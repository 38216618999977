import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';

const GridSection = (props) => {
  const { children, heading, count, counterHandlers } = props;

  const rows = [];
  const size = 4;
  const elements = React.Children.toArray(children);
  const n = elements.length;

  let i = 0;
  while (i < n) {
    rows.push(elements.slice(i, i + size));
    i += size;
  }

  let meals = 'jídel';
  if (count === 1) {
    meals = 'jidlo';
  } else if (count < 5) {
    meals = 'jídla';
  }

  return (
    <div className="section">
      <div className="heading">
        <h1>{heading.toUpperCase()}</h1>
        {count && (
          <span className="counter">
            <Button className="btn btn-default" onClick={counterHandlers.handleDecrementMeal}>
              -
            </Button>
            <input className="count" type="text" value={`${count} ${meals}`} readOnly />
            <Button className="btn btn-default" onClick={counterHandlers.handleIncrementMeal}>
              +
            </Button>
          </span>
        )}
      </div>
      <Container fluid className="p-0">
        {[...rows].map((row, rowIndex) => (
          <Row key={`row_${rowIndex}`}>
            {[...row].map((col, colIndex) => (
              <Col key={`row_${rowIndex}_col_${colIndex}`} xs={6} sm={4} md={3} lg={3}>
                {col}
              </Col>
            ))}
          </Row>
        ))}
      </Container>
    </div>
  );
};

export default GridSection;
