export const processUserOrders = (menus, orders) => {
  let userOrders = {};
  if (orders && orders.length) {
    menus.forEach((menu) => {
      const o = orders.find((order) => menu.day === order.day);
      const foodCount = o ? o.foodInfo.filter((food) => food.permanent === false).length : 0;
      const date = new Date(menu.day);
      const yesterday = new Date(date).setDate(date.getDate() - 1);
      userOrders[menu.day] = {
        ...menu,
        order: o,
        foodCount: o && foodCount > 0 ? foodCount : 1,
        orderTimeLimit: new Date(yesterday).setHours(22, 0, 0),
      };
    });
  } else {
    menus.forEach((menu) => {
      const date = new Date(menu.day);
      const yesterday = new Date(date).setDate(date.getDate() - 1);
      userOrders[menu.day] = {
        ...menu,
        foodCount: 1,
        orderTimeLimit: new Date(yesterday).setHours(22, 0, 0),
      };
    });
  }
  return userOrders;
};
