import axios from 'axios';
import { notify } from 'reapop';

import { ENDPOINTS } from '../../constants';
import {
  ALLERGENS_ALL,
  ALLERGENS_CREATE,
  ALLERGENS_FAILURE,
  ALLERGENS_LOADING,
  ALLERGENS_ONE,
  ALLERGENS_REMOVE,
  ALLERGENS_UPDATE,
} from '../types/allergensTypes';
import { processAllergens } from '../helpers/allergensHelpers';

const AAllergensLoading = (name) => ({
  type: ALLERGENS_LOADING,
  name,
});

const AAllergensFailure = (name, error) => ({
  type: ALLERGENS_FAILURE,
  name,
  error,
});

const AGetAllergens = (allergens) => ({
  type: ALLERGENS_ALL,
  allergens,
});

const AGetAllergen = (allergen) => ({
  type: ALLERGENS_ONE,
  allergen,
});

const ACreateAllergen = (allergen) => ({
  type: ALLERGENS_CREATE,
  allergen,
});

const AUpdateAllergen = (allergen) => ({
  type: ALLERGENS_UPDATE,
  allergen,
});

const ARemoveAllergen = (allergen) => ({
  type: ALLERGENS_REMOVE,
  allergen,
});

export const getAllergens = () => async (dispatch) => {
  const actionName = 'allergensAll';
  try {
    dispatch(AAllergensLoading(actionName));
    const response = await axios.get(ENDPOINTS.allergens);
    const { data } = response;
    const allergens = processAllergens(data);
    dispatch(AGetAllergens(allergens));
  } catch (e) {
    dispatch(AAllergensFailure(actionName));
    console.error(e);
  }
};

export const getAllergen = (allergenId) => async (dispatch) => {
  try {
    const response = await axios.get(`${ENDPOINTS.allergens}/${allergenId}`);
    const { data } = response;
    dispatch(AGetAllergen(data));
  } catch (e) {
    dispatch(
      notify({
        title: 'Získání alergenu',
        message: 'Nepodařilo se získat informace o alergenu',
        status: 'error',
        dismissAfter: 3000,
      }),
    );
  }
};

export const createAllergen = (token, values) => async (dispatch) => {
  const actionName = 'allergensCreate';
  dispatch(AAllergensLoading(actionName));
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.post(ENDPOINTS.allergens, values, config);
    const { data } = response;
    dispatch(ACreateAllergen(data));
    dispatch(
      notify({
        title: 'Přídání alergenu',
        message: `Alergen ${data.name} byl přidán.`,
        status: 'success',
        dismissAfter: 3000,
      }),
    );
  } catch (e) {
    dispatch(AAllergensFailure(actionName, e));
    dispatch(
      notify({
        title: 'Přídání alergenu',
        message: `Alergen se nepodařilo přidat.`,
        status: 'error',
        dismissAfter: 3000,
      }),
    );
  }
};

export const updateAllergen = (token, allergenId, values) => async (dispatch) => {
  const actionName = 'allergensUpdate';
  dispatch(AAllergensLoading(actionName));
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.put(`${ENDPOINTS.allergens}/${allergenId}`, values, config);
    const { data } = response;
    dispatch(AUpdateAllergen(data));
    dispatch(
      notify({
        title: 'Úprava alergenu',
        message: `Alergen ${data.name} byl upraven.`,
        status: 'success',
        dismissAfter: 3000,
      }),
    );
  } catch (e) {
    dispatch(AAllergensFailure(actionName, e));
    dispatch(
      notify({
        title: 'Úprava alergenu',
        message: 'Alergen se nepodařilo upravit.',
        status: 'error',
        dismissAfter: 3000,
      }),
    );
  }
};

export const removeAllergen = (token, allergen) => async (dispatch) => {
  const actionName = 'allergensRemove';
  dispatch(AAllergensLoading(actionName));
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    await axios.delete(`${ENDPOINTS.allergens}/${allergen.id}`, config);
    dispatch(ARemoveAllergen(allergen));
    dispatch(
      notify({
        title: 'Odstranění alergenu',
        message: `Alergen ${allergen.name} byl odstraněn.`,
        status: 'success',
        dismissAfter: 3000,
      }),
    );
  } catch (e) {
    dispatch(AAllergensFailure(actionName, e));
    dispatch(
      notify({
        title: 'Odstranění alergenu',
        message: `Alergen se nepodařilo odstranit.`,
        status: 'error',
        dismissAfter: 3000,
      }),
    );
  }
};
