import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons/faArrowCircleLeft';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons/faArrowCircleRight';

const WeekChanger = (props) => {
  const { previousWeek, nextWeek, text } = props;
  return (
    <Container fluid className="weekChanger">
      <Row>
        <Col xs={2}>
          <FontAwesomeIcon icon={faArrowCircleLeft} className="previous" size="3x" onClick={previousWeek} />
        </Col>
        <Col xs={8} className="text">
          {text}
        </Col>
        <Col xs={2}>
          <FontAwesomeIcon icon={faArrowCircleRight} className="next" size="3x" onClick={nextWeek} />
        </Col>
      </Row>
    </Container>
  );
};

export default WeekChanger;
