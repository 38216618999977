import React from 'react';
import { Route, Switch } from 'react-router-dom';

import RatingsList from './RatingsList';
import RatingPreview from './RatingPreview';

const Ratings = () => {
  return (
    <Switch>
      <Route path="/admin/ratings/preview/:id" component={RatingPreview} />
      <Route path="/admin/ratings" component={RatingsList} />
    </Switch>
  );
};

export default Ratings;
