import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { faHandPointLeft } from '@fortawesome/free-solid-svg-icons/faHandPointLeft';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';

import GridSection from '../../../components/Section/GridSection';
import MyButton from '../../../components/MyButton/index';
import Loading from '../../../components/Loading/index';
import Section from '../../../components/Section/Section';
import { getFoods } from '../../../redux/actions/foodsActions';
import FoodTable from '../../../components/FoodTable';

const FoodsList = () => {
  const loading = useSelector((state) => state.admin.foods.loadings.all);
  const foods = useSelector((state) => state.admin.foods.items);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading) {
      dispatch(getFoods());
    }
  }, []);

  return (
    <div>
      <GridSection heading="ADMINISTRACE">
        <MyButton link="/admin" variant="outline-dark" icon={faHandPointLeft} text="ZPĚT" />
        <MyButton link="/admin/foods/add" variant="success" icon={faPlusCircle} text="PŘIDAT JÍDLO" />
      </GridSection>
      <Section heading="SEZNAM JÍDEL">
        {loading && !Object.values(foods).length ? (
          <Loading />
        ) : (
          <Tabs>
            <Tab eventKey="all" title="Všechna jídla">
              <FoodTable type="all" />
            </Tab>
            <Tab eventKey="permanent" title="Stálá nabídka">
              <FoodTable type="permanent" />
            </Tab>
            <Tab eventKey="breakfast" title="Snídaně">
              <FoodTable type="breakfast" />
            </Tab>
            <Tab eventKey="lunch" title="Obědy">
              <FoodTable type="lunch" />
            </Tab>
            <Tab eventKey="dinner" title="Večeře">
              <FoodTable type="dinner" />
            </Tab>
          </Tabs>
        )}
      </Section>
    </div>
  );
};

export default withRouter(FoodsList);
