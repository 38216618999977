import React from 'react';
import { Route, Switch } from 'react-router-dom';

import CreditLogList from './CreditLogList';

const CreditLog = () => {
  return (
    <Switch>
      <Route exact path="/admin/creditLog" component={CreditLogList} />
    </Switch>
  );
};

export default CreditLog;
