import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faHandPointLeft } from '@fortawesome/free-solid-svg-icons/faHandPointLeft';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { Col, Table } from 'react-bootstrap';
import moment from 'moment';

import GridSection from '../../../components/Section/GridSection';
import MyButton from '../../../components/MyButton/index';
import Section from '../../../components/Section/Section';
import Loading from '../../../components/Loading/index';
import { getAllergen, removeAllergen } from '../../../redux/actions/allergensActions';

const AllergenPreview = () => {
  const match = useRouteMatch();
  const history = useHistory();

  const token = useSelector((state) => state.user.token);
  const loading = useSelector((state) => state.admin.allergens.loadings.allergensOne);
  const loadingRemove = useSelector((state) => state.admin.allergens.loadings.allergensRemove);
  const errorRemove = useSelector((state) => state.admin.allergens.errors.allergensRemove);
  const allergen = useSelector((state) => state.admin.allergens.items[match.params.id]);

  const dispatch = useDispatch();

  const [isRemoved, setIsRemoved] = useState(false);

  useEffect(() => {
    if (!loading) {
      dispatch(getAllergen(match.params.id));
    }
  }, []);

  useEffect(() => {
    if (isRemoved && !loadingRemove && !errorRemove) {
      setIsRemoved(false);
      history.goBack();
    }
  }, [loadingRemove, errorRemove]);

  const handleRemove = () => {
    setIsRemoved(true);
    dispatch(removeAllergen(token, allergen));
  };

  if (!allergen) return null;

  return (
    <div>
      <GridSection heading="ADMINISTRACE">
        <MyButton link="/admin/allergens" variant="outline-dark" icon={faHandPointLeft} text="ZPĚT" />
        <MyButton link={`/admin/allergens/edit/${allergen.id}`} variant="warning" icon={faEdit} text="UPRAVIT ALERGEN" />
        <MyButton variant="danger" icon={faTrash} text="ODSTRANIT ALERGEN" action={handleRemove} />
      </GridSection>
      <Section heading="Náhled alergenu">
        {loading ? (
          <Loading />
        ) : (
          <Col xs={{ span: 8, offset: 2 }}>
            <Table striped responsive>
              <tbody>
                <tr>
                  <th>Název:</th>
                  <td>{allergen.name}</td>
                </tr>
                <tr>
                  <th>Vytvořeno:</th>
                  <td>{moment(allergen.createdAt).format('LLLL')}</td>
                </tr>
                <tr>
                  <th>Upraveno:</th>
                  <td>{moment(allergen.updatedAt).format('LLLL')}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        )}
      </Section>
    </div>
  );
};

export default AllergenPreview;
