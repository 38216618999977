import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import jsPDF from 'jspdf';
import { remove as removeDiacritics } from 'diacritics';

import { getPermanentMenu, orderPermanentFood } from '../../redux/actions/kitchenActions';
import Food from '../Food';
import Loading from '../Loading';
import onSite from '../../assets/images/onsite.png';
import takeOut from '../../assets/images/takeout.png';

const PermanentFoodSection = () => {
  const token = useSelector((state) => state.user.token);
  const user = useSelector((state) => state.user.user);

  const loading = useSelector((state) => state.kitchen.loadings.permanentMenu);
  const permanentMenu = useSelector((state) => state.kitchen.permanentMenu);
  const menu = useSelector((state) => state.kitchen.menu);

  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!loading && !menu) {
      dispatch(getPermanentMenu());
    }
  }, [token]);

  const handleOrderPermanentFood = async (food, takeout = false) => {
    if (user) {
      dispatch(orderPermanentFood(token, user.id, menu, food, takeout));
      setShowModal(false);
      handlePrintPermanentOrder(food);
    }
  };

  const handlePrintPermanentOrder = (food) => {
    const doc = new jsPDF('landscape', 'mm', 'a7');
    doc.setFont('times');
    doc.setFontSize(20);
    doc.text(removeDiacritics(`${user.firstname} ${user.surname}`), 52, 10, 'center');
    doc.text(removeDiacritics(food.name), 52, 20, 'center');
    doc.setFontSize(10);
    doc.text(removeDiacritics(moment().format('LLL')), 52, 30, 'center');
    doc.text('----------', 52, 70, 'center');
    doc.autoPrint();
    const w = window.open(doc.output('bloburl'), '_blank');
    const interval = setInterval(() => {
      if (w) {
        w.close();
      } else {
        clearInterval(interval);
      }
    }, 5000);
  };

  const foods = permanentMenu ? permanentMenu.filter((f) => !/\+.*navíc$/.test(f.longName) && !f.disabled).sort((a, b) => a.order - b.order) : [];
  const inAdditionFoods = permanentMenu ? permanentMenu.filter((f) => /\+.*navíc/.test(f.longName) && !f.disabled) : [];
  const inAdditionFood = inAdditionFoods.find((f) => {
    const date = new Date();
    const breakfastStart = new Date().setHours(0, 0, 0, 0);
    const breakfastEnd = new Date().setHours(11, 0, 0, 0);
    const lunchStart = new Date().setHours(11, 0, 0, 0);
    const lunchEnd = new Date().setHours(15, 0, 0, 0);
    const dinnerStart = new Date().setHours(15, 0, 0, 0);
    const dinnerEnd = new Date().setHours(24, 0, 0, 0);
    if (breakfastStart < date && date < breakfastEnd) {
      return f.longName.includes('+ Snídaně navíc');
    }
    if (lunchStart < date && date < lunchEnd) {
      return f.longName.includes('+ Oběd navíc');
    }
    if (dinnerStart < date && date < dinnerEnd) {
      return f.longName.includes('+ Večeře navíc');
    }
    return false;
  });

  if (inAdditionFood) foods.push(inAdditionFood);

  const disabled = !user;

  return (
    <div className="section">
      <h1>{`DNES ${moment().format('LL')}`}</h1>
      <Container fluid className="p-0">
        {loading ? (
          <Loading />
        ) : (
          <Row className="d-row">
            {foods.length
              ? foods.map((food) => {
                  const orderedFoodCount = menu && menu.order ? menu.order.foodInfo.filter((item) => item.id === food.id).length : 0;
                  const takeoutCount = menu && menu.order ? menu.order.foodInfo.filter((item) => item.id === food.id && item.takeout === true).length : 0;
                  const onsiteCount = menu && menu.order ? menu.order.foodInfo.filter((item) => item.id === food.id && item.takeout === false).length : 0;
                  return (
                    <Col key={`col_food_${food.id}`}>
                      {food === inAdditionFood ? (
                        <Food
                          disabled={disabled}
                          data={food}
                          ordered={food.ordered}
                          onsiteCount={onsiteCount}
                          takeoutCount={takeoutCount}
                          action={!disabled ? () => setShowModal(true) : undefined}
                        />
                      ) : (
                        <Food
                          disabled={disabled}
                          data={food}
                          ordered={food.ordered}
                          orderedCount={orderedFoodCount}
                          action={!disabled ? () => handleOrderPermanentFood(food) : undefined}
                        />
                      )}
                    </Col>
                  );
                })
              : null}
            {foods.length < 4 && [...new Array(5 - foods.length)].map((v, i) => <Col key={`col_${i}`} />)}
          </Row>
        )}
      </Container>
      {inAdditionFood ? (
        <Modal show={showModal} size="lg" onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Budete jíst u nás nebo chcete jídlo s sebou?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h3 className="text-center p-4">{inAdditionFood.name}</h3>
            <Row>
              <Col>
                <Food data={{ longName: 'Konzumace na místě' }} image={onSite} action={() => handleOrderPermanentFood(inAdditionFood, false)} />
              </Col>
              <Col>
                <Food data={{ longName: 'Zabalit s sebou' }} image={takeOut} action={() => handleOrderPermanentFood(inAdditionFood, true)} />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-dark" onClick={() => setShowModal(false)}>
              Zavřít
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </div>
  );
};

export default PermanentFoodSection;
