import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Button, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { faHandPointLeft } from '@fortawesome/free-solid-svg-icons/faHandPointLeft';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { notify } from 'reapop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';

import GridSection from '../../../components/Section/GridSection';
import MyButton from '../../../components/MyButton/index';
import Section from '../../../components/Section/Section';
import Loading from '../../../components/Loading/index';
import OrderModal from '../../../components/OrderModal';
import { getUser } from '../../../redux/actions/usersActions';
import { ENDPOINTS, KITCHEN_PASSWORD } from '../../../constants';

const UserCreditLog = () => {
  const match = useRouteMatch();

  const token = useSelector((state) => state.user.token);
  const loading = useSelector((state) => state.admin.users.loadings.one);
  const user = useSelector((state) => state.admin.users.items[match.params.id]);

  const dispatch = useDispatch();

  const [loadingCreditLog, setLoadingCreditLog] = useState(false);
  const [creditLog, setCreditLog] = useState([]);
  const [totalBill, setTotalBill] = useState(0);

  const [order, setOrder] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (match.params.id && !loading) {
      dispatch(getUser(token, match.params.id));
      getUserCreditLog(token);
    }
  }, []);

  const getUserCreditLog = async () => {
    setLoadingCreditLog(true);

    const config = {
      headers: {
        authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios.get(`${ENDPOINTS.creditLog}/${match.params.id}`, config);
      const { data } = response;
      const creditLog = data.map((creditLog) => {
        const created = new Date(creditLog.createdAt);
        const timezoneOffset = created.getTimezoneOffset();
        const createdAt = new Date(created.getTime() + timezoneOffset * 60 * 1000);
        return {
          ...creditLog,
          date: new Date(createdAt),
        };
      });
      const totalBill = data.reduce((acc, creditLog) => {
        const balance = creditLog.balance || 0;
        const bill = creditLog.bill || 0;
        const change = Math.round((balance - bill) * 100) / 100;
        return acc + change;
      }, 0);
      setCreditLog(creditLog);
      setTotalBill(totalBill);
      setLoadingCreditLog(false);
    } catch (e) {
      console.error(e);
      dispatch(
        notify({
          title: 'Výpis změn kreditu',
          message: 'Nepodařilo se získat výpis změn kreditu',
          status: 'error',
          dismissAfter: 3000,
        }),
      );
    }
  };

  const getOrder = async (date) => {
    const config = {
      headers: {
        authorization: `Bearer ${token}`,
      },
      params: {
        tkn: KITCHEN_PASSWORD,
        userId: user.id,
        from: moment(date).utc().startOf('day').toISOString(),
        to: moment(date).utc().endOf('day').toISOString(),
      },
    };
    try {
      const response = await axios.get(`${ENDPOINTS.orders}`, config);
      const order = response.data && response.data.length ? response.data[0] : undefined;
      if (order) {
        const uniqueFoods = [];

        let mainMealsCount = 0;

        order.foodInfo.forEach((food) => {
          if (!food.permanent) {
            mainMealsCount += 1;
          }

          if (!uniqueFoods.find((elem) => elem.id === food.id)) {
            uniqueFoods.push(food);
          }
        });

        let discountUsed = false;
        order.foodInfo = uniqueFoods.map((food) => {
          const f = food;
          f.onsiteCount = order.foodInfo.filter((elem) => elem.id === food.id && elem.takeout === false).length;
          f.takeoutCount = order.foodInfo.filter((elem) => elem.id === food.id && elem.takeout === true).length;
          f.totalCount = order.foodInfo.filter((elem) => elem.id === food.id).length;
          if (!discountUsed) {
            f.discount = order.userId.cardId.match(/MOV/) && mainMealsCount >= 1;
            discountUsed = true;
          }
          return f;
        });
      }
      setOrder(order);
    } catch (e) {
      dispatch(
        notify({
          title: 'Získání objednávky',
          message: 'Objednávku se nepodařilo získat',
          status: 'error',
          dismissAfter: 3000,
        }),
      );
    }
  };

  const tooltipView = <Tooltip id="tooltip-view">Zobrazit objednávku</Tooltip>;

  if (!user) return null;

  return (
    <div>
      <GridSection heading="ADMINISTRACE">
        <MyButton link="/admin/users" variant="outline-dark" icon={faHandPointLeft} text="ZPĚT" />
      </GridSection>
      <Section heading="PŘEHLED ZMĚN ZŮSTATKU">
        {loadingCreditLog ? (
          <Loading />
        ) : (
          <Table responsive striped>
            <thead>
              <tr>
                <th
                  colSpan={4}
                  style={{
                    textAlign: 'center',
                  }}>{`Přehled změn zůstatku na účtě: ${user.firstname} ${user.surname} (${user.cardId})`}</th>
              </tr>
              <tr>
                <th colSpan={3}>Celkem na účtě:</th>
                <th style={{ textAlign: 'right', color: totalBill < 0 ? 'red' : '' }}>{totalBill.toFixed(2)} Kč</th>
              </tr>
              <tr>
                <th>Datum</th>
                <th>Informace o změně</th>
                <th>Změnu provedl</th>
                <th style={{ textAlign: 'right' }}>Změna na účtě</th>
              </tr>
            </thead>
            <tbody>
              {creditLog && creditLog.length ? (
                [...creditLog].map((log) => {
                  const balance = log.balance || 0;
                  const bill = log.bill || 0;
                  const change = balance - bill;
                  return (
                    <tr key={`user_${log._id}`}>
                      <td>{moment(log.date).format('DD.MM.YYYY HH:mm')}</td>
                      {bill && !balance ? (
                        <td>
                          Platba objednávky
                          <OverlayTrigger placement="top" overlay={tooltipView}>
                            <Button
                              variant="light"
                              style={{ marginLeft: '20px' }}
                              onClick={() => {
                                getOrder(log.createdAt);
                                setShowModal(true);
                              }}>
                              <FontAwesomeIcon icon={faEye} />
                            </Button>
                          </OverlayTrigger>
                        </td>
                      ) : (
                        <td>Změna zůstatku</td>
                      )}
                      <td>{`${log.userId.firstname} ${log.userId.surname}`}</td>
                      <td style={{ textAlign: 'right', color: change < 0 ? 'red' : '' }}>{change.toFixed(2)} Kč</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={5} style={{ textAlign: 'center' }}>
                    Žádné dostupné záznamy.
                  </td>
                </tr>
              )}
              <tr>
                <th colSpan={3}>Celkem na účtě:</th>
                <th style={{ textAlign: 'right', color: totalBill < 0 ? 'red' : '' }}>{totalBill.toFixed(2)} Kč</th>
              </tr>
            </tbody>
          </Table>
        )}
      </Section>
      <OrderModal show={showModal && order} order={order} onHide={() => setShowModal(false)} />
    </div>
  );
};

export default UserCreditLog;
