import React from 'react';
import { Button, Container } from 'react-bootstrap';

const Section = (props) => {
  const { children, heading, count, counterHandlers } = props;

  let meals = 'jídel';
  if (count === 1) {
    meals = 'jidlo';
  } else if (count < 5) {
    meals = 'jídla';
  }

  return (
    <div className="section">
      <h1>{heading.toUpperCase()}</h1>
      {count && (
        <div className="counter">
          <Button variant="light" onClick={counterHandlers.handleDecrementMeal}>
            -
          </Button>
          <div className="count">{`${count} ${meals}`}</div>
          <Button variant="light" onClick={counterHandlers.handleIncrementMeal}>
            +
          </Button>
        </div>
      )}
      <Container fluid className="p-0">
        {children}
      </Container>
    </div>
  );
};

export default Section;
