import React from 'react';
import { Route, Switch } from 'react-router-dom';

import UsersList from './UsersList';
import UserPreview from './UserPreview';
import UserCreditLog from './UserCreditLog';
import UserForms from './UserForms';

const Users = () => {
  return (
    <Switch>
      <Route path="/admin/users/add" component={UserForms} />
      <Route path="/admin/users/edit/:id" component={UserForms} />
      <Route path="/admin/users/preview/:id" component={UserPreview} />
      <Route path="/admin/users/creditLog/:id" component={UserCreditLog} />
      <Route path="/admin/users" component={UsersList} />
    </Switch>
  );
};

export default Users;
