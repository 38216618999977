import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { faHandPointLeft } from '@fortawesome/free-solid-svg-icons/faHandPointLeft';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { Col, Row, Table } from 'react-bootstrap';

import GridSection from '../../../components/Section/GridSection';
import MyButton from '../../../components/MyButton/index';
import Section from '../../../components/Section/Section';
import Loading from '../../../components/Loading/index';
import { getFood, removeFood } from '../../../redux/actions/foodsActions';
import { __API_URL__ } from '../../../constants';

const FoodPreview = () => {
  const match = useRouteMatch();
  const history = useHistory();

  const token = useSelector((state) => state.user.token);
  const loading = useSelector((state) => state.admin.foods.loadings.one);
  const loadingRemove = useSelector((state) => state.admin.foods.loadings.remove);
  const errorRemove = useSelector((state) => state.admin.foods.errors.remove);
  const food = useSelector((state) => state.admin.foods.items[match.params.id]);

  const dispatch = useDispatch();

  const [isRemoved, setIsRemoved] = useState(false);

  useEffect(() => {
    if (!loading) {
      dispatch(getFood(match.params.id));
    }
  }, []);

  useEffect(() => {
    if (isRemoved && !loadingRemove && !errorRemove) {
      setIsRemoved(false);
      history.goBack();
    }
  }, [loadingRemove, errorRemove]);

  const handleRemove = () => {
    setIsRemoved(true);
    dispatch(removeFood(token, food));
  };

  const getFoodType = (type) => {
    switch (type) {
      case 'breakfast':
        return 'snídaně';
      case 'lunch':
        return 'oběd';
      case 'dinner':
        return 'večeře';
      default:
        return '';
    }
  };

  if (!food) return null;

  return (
    <div>
      <GridSection heading="ADMINISTRACE">
        <MyButton link="/admin/foods" variant="outline-dark" icon={faHandPointLeft} text="ZPĚT" />
        <MyButton link={`/admin/foods/edit/${match.params.id}`} variant="warning" icon={faEdit} text="UPRAVIT JÍDLO" />
        <MyButton variant="danger" icon={faTrash} text="ODSTRANIT JÍDLO" action={handleRemove} />
      </GridSection>
      <Section heading="NÁHLED JÍDLA">
        {loading ? (
          <Loading />
        ) : (
          <Row>
            <Col xs={food.picture ? 8 : 12}>
              <h5>Informace o jídle</h5>
              <Table responsive striped>
                <tbody>
                  <tr>
                    <th>Název:</th>
                    <td>{food.name}</td>
                  </tr>
                  <tr>
                    <th>Celý název:</th>
                    <td>{food.longName}</td>
                  </tr>
                  <tr>
                    <th>Typ jídla:</th>
                    <td>{getFoodType(food.type)}</td>
                  </tr>
                  <tr>
                    <th>Cena:</th>
                    <td>{food.price}</td>
                  </tr>
                  <tr>
                    <th>Popis:</th>
                    <td>{food.description}</td>
                  </tr>
                  <tr>
                    <th>Přísady:</th>
                    <td>{food.ingredients}</td>
                  </tr>
                  <tr>
                    <th>Alergeny:</th>
                    <td>{food.allergens.map((allergen) => allergen.name).join(', ')}</td>
                  </tr>
                  <tr>
                    <th>Stálá nabídka:</th>
                    <td>{food.permanent ? 'ano' : 'ne'}</td>
                  </tr>
                  <tr>
                    <th>Počet výskytů na jídelníčku:</th>
                    <td>{food.onTheMenu}</td>
                  </tr>
                  <tr>
                    <th>Celkový počet objednávek:</th>
                    <td>{food.orderedTimes}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            {food.picture && (
              <Col xs={{ span: 3, offset: 1 }}>
                <h5>Náhled jídla</h5>
                <div className="food-preview">
                  <img className="img-responsive img-thumbnail" src={`${__API_URL__}/${food.picture}`} alt="náhled jídla" />
                </div>
              </Col>
            )}
          </Row>
        )}
      </Section>
    </div>
  );
};

export default withRouter(FoodPreview);
