import { Button, Form } from 'react-bootstrap';
import React, { useEffect, useRef } from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { adminLogin } from '../../redux/actions/userActions';

import { AdminLoginValidationSchema } from './validation';

const AdminLoginForm = () => {
  const loading = useSelector((state) => state.user.loadings.userLogin);

  const dispatch = useDispatch();

  const refInput = useRef();

  useEffect(() => {
    if (refInput && refInput.current) {
      refInput.current.focus();
    }
  }, []);

  const submitForm = (values) => {
    dispatch(adminLogin(values));
  };

  return (
    <Formik initialValues={{ email: '', password: '' }} enableReinitialize validationSchema={AdminLoginValidationSchema} onSubmit={submitForm}>
      {({ values, errors, touched, handleChange, handleSubmit }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group controlId="email">
            <Form.Control
              ref={refInput}
              type="email"
              name="email"
              value={values.email}
              placeholder="Zadejte email"
              onChange={handleChange}
              isInvalid={touched.email && errors.email}
            />
            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="password">
            <Form.Control
              type="password"
              name="password"
              value={values.password}
              placeholder="Zadejte heslo"
              onChange={handleChange}
              autoComplete="off"
              isInvalid={touched.password && errors.password}
            />
            <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
          </Form.Group>
          <Button disabled={loading} className="btn btn-login" type="submit">
            PŘIHLÁSIT
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default AdminLoginForm;
