import React, { useEffect, useState } from 'react';
import moment from 'moment';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { notify } from 'reapop';
import { faHandPointLeft } from '@fortawesome/free-solid-svg-icons/faHandPointLeft';
import { Table } from 'react-bootstrap';

import GridSection from '../../../components/Section/GridSection';
import MyButton from '../../../components/MyButton';
import Loading from '../../../components/Loading';
import Section from '../../../components/Section/Section';
import WeekChanger from '../../../components/WeekChanger';
import { ENDPOINTS } from '../../../constants';
import { getCorrectDate } from '../../../utils';

const CreditLogList = () => {
  const token = useSelector((state) => state.user.token);

  const dispatch = useDispatch();

  const [from, setFrom] = useState(moment().startOf('week'));
  const [to, setTo] = useState(moment().endOf('week'));
  const [loading, setLoading] = useState(false);
  const [creditLog, setCreditLog] = useState([]);

  useEffect(() => {
    if (!loading) {
      getCreditLog();
    }
  }, []);

  const getCreditLog = async () => {
    setLoading(true);

    const fromDate = getCorrectDate(from).toISOString();
    const toDate = getCorrectDate(to).toISOString();

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        from: fromDate,
        to: toDate,
      },
    };

    try {
      const response = await axios.get(ENDPOINTS.creditLog, config);
      const { data } = response;
      const creditLog = data.map((creditLog) => {
        const created = new Date(creditLog.createdAt);
        const timezoneOffset = created.getTimezoneOffset();
        const createdAt = new Date(created.getTime() + timezoneOffset * 60 * 1000);
        return {
          ...creditLog,
          createdAt: new Date(createdAt),
        };
      });
      setCreditLog(creditLog);
      setLoading(false);
    } catch (e) {
      dispatch(
        notify({
          title: 'Výpis změn kreditu',
          message: 'Nepodařilo se načíst seznam změn kreditu pro dané období',
          status: 'error',
        }),
      );
    }
  };

  const handlePreviousWeek = () => {
    setFrom(from.subtract(1, 'week'));
    setTo(to.subtract(1, 'week'));
    getCreditLog();
  };

  const handleNextWeek = () => {
    setFrom(from.add(1, 'week'));
    setTo(to.add(1, 'week'));
    getCreditLog();
  };

  return (
    <div>
      <GridSection heading="ADMINISTRACE">
        <MyButton link="/admin" variant="outline-dark" icon={faHandPointLeft} text="ZPĚT" />
      </GridSection>
      <Section heading="VÝPIS ZMĚN KREDITU">
        {loading ? (
          <Loading />
        ) : (
          <>
            <WeekChanger text={`${moment(from).week()}. týden`} previousWeek={handlePreviousWeek} nextWeek={handleNextWeek} />
            <Table responsive striped>
              <thead>
                <tr>
                  <th>Datum</th>
                  <th>ID karty</th>
                  <th>Uživatel (email)</th>
                  <th>E-mail</th>
                  <th>Změnu provedl</th>
                  <th style={{ textAlign: 'right' }}>Změna na účtě</th>
                </tr>
              </thead>
              <tbody>
                {creditLog && creditLog.length ? (
                  [...creditLog].map((log) => {
                    const balance = log.balance || 0;
                    const bill = log.bill || 0;
                    const change = Math.round((balance - bill) * 100) / 100;
                    return (
                      <tr key={`user_${log.id}`}>
                        <td>{moment(log.createdAt).format('DD.MM.YYYY HH:mm')}</td>
                        <td>{log.accountId.userId.cardId}</td>
                        <td>{`${log.accountId.userId.firstname} ${log.accountId.userId.surname}`}</td>
                        <td>{log.accountId.userId.email}</td>
                        <td>{`${log.userId.firstname} ${log.userId.surname}`}</td>
                        <td style={{ textAlign: 'right', color: change < 0 ? 'red' : '' }}>{change} Kč</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={6} style={{ textAlign: 'center' }}>
                      Žádné dostupné záznamy.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </>
        )}
      </Section>
    </div>
  );
};

export default withRouter(CreditLogList);
