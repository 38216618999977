import * as Yup from 'yup';

export const UserLoginValidationSchema = Yup.object().shape({
  cardId: Yup.string().length(6, 'ID karty musí mít 6 číslic').required('Musí být vyplněno!'),
});

export const AdminLoginValidationSchema = Yup.object().shape({
  email: Yup.string().email('Musí splňovat formát emailu. Např.: email@domena.cz').required('Musí být vyplněn!'),
  password: Yup.string().required('Musí být vyplněno!'),
});

export const UserFormValidationSchema = Yup.object().shape({
  firstname: Yup.string().required('Jméno musí být vyplněno!'),
  surname: Yup.string().required('Příjmení musí být vyplněno!'),
  email: Yup.string().email('Email musí splňovat formát emailu. Např.: email@domena.cz').required('Email musí být vyplněn!'),
  cardId: Yup.string().required('Email musí být vyplněn!'),
});
