import { ORDERS_ALL, ORDERS_DAY, ORDERS_FAILURE, ORDERS_LOADING, ORDERS_ONE, ORDERS_REMOVE, ORDERS_UPDATE } from '../../types/ordersTypes';

const INITIAL_STATE = {
  loadings: {},
  errors: {},
  items: {},
  activeOrders: [],
  activeDayOrders: [],
};

const ordersReducer = (currentState = INITIAL_STATE, action) => {
  let newState;
  switch (action.type) {
    case ORDERS_LOADING:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: true,
        },
      };
    case ORDERS_FAILURE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: false,
        },
        errors: {
          ...currentState.errors,
          [action.name]: action.error,
        },
      };
    case ORDERS_ALL:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          all: false,
        },
        errors: {
          ...currentState.errors,
          all: undefined,
        },
        items: {
          ...currentState.items,
          ...action.orders,
        },
        activeOrders: action.activeOrders,
      };
    case ORDERS_DAY:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          day: false,
        },
        errors: {
          ...currentState.errors,
          day: undefined,
        },
        items: {
          ...currentState.items,
          ...action.orders,
        },
        activeDayOrders: action.activeDayOrders,
      };
    case ORDERS_ONE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          one: false,
        },
        errors: {
          ...currentState.errors,
          one: undefined,
        },
        items: {
          ...currentState.items,
          [action.order.id]: action.order,
        },
      };
    case ORDERS_UPDATE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          update: false,
        },
        errors: {
          ...currentState.errors,
          update: undefined,
        },
        items: {
          ...currentState.items,
          [action.order.id]: {
            ...currentState.items[action.order.id],
            ...action.order,
          },
        },
      };
    case ORDERS_REMOVE:
      newState = {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          remove: false,
        },
        errors: {
          ...currentState.errors,
          remove: undefined,
        },
        items: {
          ...currentState.items,
        },
      };
      delete newState.items[action.order.id];
      return newState;
    default:
      return currentState;
  }
};

export default ordersReducer;
