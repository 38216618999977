import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { NotificationsProvider } from 'reapop';

import 'moment/locale/cs';

import './styles/index.scss';
import './styles/files.scss';
import 'bootstrap/dist/css/bootstrap.css';

import store from './redux/store';
import App from './App';
import Notifications from './Notifications';

render(
  <Provider store={store}>
    <BrowserRouter>
      <NotificationsProvider>
        <Notifications />
        <App />
      </NotificationsProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('app'),
);
