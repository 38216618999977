import React, { useEffect, useState } from 'react';
import { Button, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons/faDollarSign';
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons/faExchangeAlt';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons/faMinusCircle';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faHandPointLeft } from '@fortawesome/free-solid-svg-icons/faHandPointLeft';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import { remove as removeDiacritics } from 'diacritics';

import GridSection from '../../../components/Section/GridSection';
import MyButton from '../../../components/MyButton';
import Loading from '../../../components/Loading';
import Section from '../../../components/Section/Section';
import CreditModal from '../../../components/CreditModal';
import { changeUserActive, getUsers } from '../../../redux/actions/usersActions';
import UserFilter from '../../../components/Filters/UserFilter';

const UsersList = () => {
  const history = useHistory();

  const token = useSelector((state) => state.user.token);
  const loading = useSelector((state) => state.admin.users.loadings.all);
  const users = useSelector((state) => state.admin.users.items);
  const usersArray = Object.values(users).filter((u) => u.role === 'user');

  const dispatch = useDispatch();

  const [filteredUsers, setFilteredUsers] = useState(usersArray);
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState();

  useEffect(() => {
    if (!loading) {
      dispatch(getUsers(token));
    }
  }, []);

  useEffect(() => {
    handleFilter('');
  }, [users]);

  const handleFilter = (search) => {
    const searchedText = removeDiacritics(search).toLowerCase();
    setFilteredUsers(
      usersArray.filter((user) => {
        const cardIdFilter = removeDiacritics(user.cardId).toLowerCase().includes(searchedText);
        const nameFilter = removeDiacritics(`${user.firstname} ${user.surname}`).toLowerCase().includes(searchedText);
        const emailFilter = removeDiacritics(user.email).toLowerCase().includes(searchedText);
        return cardIdFilter || nameFilter || emailFilter;
      }),
    );
  };

  const handleChangeActive = (user) => {
    dispatch(changeUserActive(token, user.id, user.active));
  };

  const tooltipView = <Tooltip id="tooltip-view">Zobrazit uživatele</Tooltip>;
  const tooltipEdit = <Tooltip id="tooltip-edit">Upravit uživatele</Tooltip>;
  const tooltipDisable = <Tooltip id="tooltip-disable">Deaktivovat uživatele</Tooltip>;
  const tooltipEnable = <Tooltip id="tooltip-enable">Aktivovat uživatele</Tooltip>;
  const tooltipBalance = <Tooltip id="tooltip-balance">Upravit zůstatek</Tooltip>;
  const tooltipCreditLog = <Tooltip id="tooltip-balance">Zobrazit log zůstatku</Tooltip>;

  const usersSorted = filteredUsers.sort((u1, u2) => {
    const fullName1 = `${u1.firstname} ${u1.surname}`;
    const fullName2 = `${u2.firstname} ${u2.surname}`;
    return fullName1.localeCompare(fullName2);
  });

  return (
    <div>
      <GridSection heading="ADMINISTRACE">
        <MyButton link="/admin" variant="outline-dark" icon={faHandPointLeft} text="ZPĚT" />
        <MyButton link="/admin/users/add" variant="success" icon={faPlusCircle} text="PŘIDAT UŽIVATELE" />
      </GridSection>
      <Section heading="SEZNAM UŽIVATELŮ">
        {loading && !usersSorted.length ? (
          <Loading />
        ) : (
          <>
            <UserFilter handleFilter={handleFilter} />
            <Table responsive striped>
              <thead>
                <tr>
                  <th>ID karty</th>
                  <th>Jméno</th>
                  <th>E-mail</th>
                  <th style={{ textAlign: 'right' }}>Stav účtu</th>
                  <th style={{ textAlign: 'right' }}>Možnosti</th>
                </tr>
              </thead>
              <tbody>
                {usersSorted.length ? (
                  usersSorted.map((user) => {
                    const balance = Math.round((user.credit.balance - user.credit.bill) * 100) / 100;
                    return (
                      <tr key={`user_${user.id}`}>
                        <td>{user.cardId}</td>
                        <td>{`${user.firstname} ${user.surname}`}</td>
                        <td>{user.email}</td>
                        <td style={{ textAlign: 'right', color: balance < 0 ? 'red' : '' }}>{balance.toFixed(2)} Kč</td>
                        <td style={{ textAlign: 'right' }}>
                          <OverlayTrigger placement="top" overlay={tooltipView}>
                            <Button variant="light" onClick={() => history.push(`/admin/users/preview/${user.id}`)}>
                              <FontAwesomeIcon icon={faEye} />
                            </Button>
                          </OverlayTrigger>
                          <OverlayTrigger placement="top" overlay={tooltipBalance}>
                            <Button
                              variant="light"
                              onClick={() => {
                                setSelectedUser(user);
                                setShowModal(true);
                              }}>
                              <FontAwesomeIcon icon={faDollarSign} />
                            </Button>
                          </OverlayTrigger>
                          <OverlayTrigger placement="top" overlay={tooltipCreditLog}>
                            <Button variant="light" onClick={() => history.push(`/admin/users/creditLog/${user.id}`)}>
                              <FontAwesomeIcon icon={faExchangeAlt} />
                            </Button>
                          </OverlayTrigger>
                          <OverlayTrigger placement="top" overlay={tooltipEdit}>
                            <Button variant="light" onClick={() => history.push(`/admin/users/edit/${user.id}`)}>
                              <FontAwesomeIcon icon={faEdit} />
                            </Button>
                          </OverlayTrigger>
                          <OverlayTrigger placement="top" overlay={user.active ? tooltipDisable : tooltipEnable}>
                            <Button variant={user.active ? 'danger' : 'success'} onClick={() => handleChangeActive(user)}>
                              <FontAwesomeIcon icon={user.active ? faMinusCircle : faCheckCircle} />
                            </Button>
                          </OverlayTrigger>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={5} className="text-center">
                      Filtru neodpovídají žádní uživatelé
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            <CreditModal show={showModal} user={selectedUser} onHide={() => setShowModal(!showModal)} />
          </>
        )}
      </Section>
    </div>
  );
};

export default UsersList;
