import React from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import moment from 'moment';

import Loading from '../Loading';
import { getPackagingPrice, priceList } from '../../constants/priceList';

const OrderModal = (props) => {
  const { show, loading, order, onHide } = props;

  if (!order) return null;

  let orderPrice = 0;
  return (
    <Modal show={show} onHide={onHide} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Náhled objednávky uživatele: {order.userId ? `${order.userId.firstname} ${order.userId.surname}` : ''}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Loading />
        ) : (
          <>
            <Table responsive striped>
              <tbody>
                <tr>
                  <th>Datum:</th>
                  <td>{moment(order.day).format('dddd D. M. YYYY')}</td>
                </tr>
                <tr>
                  <th>Potvrzená:</th>
                  <td>{order.confirmed ? 'ano' : 'ne'}</td>
                </tr>
                <tr>
                  <th>Vyzvednutá:</th>
                  <td>{order.done ? 'ano' : 'ne'}</td>
                </tr>
                <tr>
                  <th>Vyzvednutá snídaně:</th>
                  <td>{order.breakfastPickedUp ? 'ano' : 'ne'}</td>
                </tr>
                <tr>
                  <th>Vyzvednutý oběd:</th>
                  <td>{order.lunchPickedUp ? 'ano' : 'ne'}</td>
                </tr>
                <tr>
                  <th>Vyzvednutá večeře:</th>
                  <td>{order.dinnerPickedUp ? 'ano' : 'ne'}</td>
                </tr>
              </tbody>
            </Table>
            <Table responsive striped>
              <tbody>
                <tr>
                  <th colSpan={8} style={{ textAlign: 'center' }}>
                    Obsah objednávky
                  </th>
                </tr>
                <tr>
                  <th>Název</th>
                  <th style={{ width: '100px' }}>Na místě</th>
                  <th style={{ width: '100px' }}>S sebou</th>
                  <th style={{ width: '100px' }}>Celkem</th>
                  <th className="text-right" style={{ width: '100px' }}>
                    Cena
                  </th>
                  <th className="text-right" style={{ width: '100px' }}>
                    Sleva
                  </th>
                  <th className="text-right" style={{ width: '100px' }}>
                    Obaly
                  </th>
                  <th className="text-right" style={{ width: '150px' }}>
                    Cena celkem
                  </th>
                </tr>
                {[...order.foodInfo].map((food) => {
                  const orderDate = new Date(order.day);
                  const packaging = food.takeoutCount * getPackagingPrice(orderDate);
                  let price = priceList(order.userId, orderDate, food, 1);
                  let discountPrice = food.discount ? priceList(order.userId, orderDate, food, 0) : price;
                  let discount = price - discountPrice;
                  let totalPrice = food.totalCount * price - discount + packaging;
                  orderPrice += totalPrice;
                  return (
                    <tr key={`food_${food._id}`}>
                      <td>{food.longName}</td>
                      <td className="text-center">{`${food.onsiteCount}x`}</td>
                      <td className="text-center">{`${food.takeoutCount}x`}</td>
                      <td className="text-center">{`${food.totalCount}x`}</td>
                      <td className="text-right">{`${price} Kč`}</td>
                      <td className="text-right">{`${discount} Kč`}</td>
                      <td className="text-right">{`${packaging} Kč`}</td>
                      <td className="text-right">{`${totalPrice} Kč`}</td>
                    </tr>
                  );
                })}
                <tr>
                  <th colSpan={7}>Celkem za objednávku:</th>
                  <th className="text-right">{`${orderPrice.toFixed(2)} Kč`}</th>
                </tr>
              </tbody>
            </Table>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={onHide}>
          Zavřít
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default OrderModal;
