import {
  ALLERGENS_ALL,
  ALLERGENS_CREATE,
  ALLERGENS_FAILURE,
  ALLERGENS_LOADING,
  ALLERGENS_ONE,
  ALLERGENS_REMOVE,
  ALLERGENS_UPDATE,
} from '../../types/allergensTypes';

const INITIAL_STATE = {
  loadings: {},
  errors: {},
  items: {},
};

const allergensReducer = (currentState = INITIAL_STATE, action) => {
  let newState;
  switch (action.type) {
    case ALLERGENS_LOADING:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: true,
        },
      };
    case ALLERGENS_FAILURE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: false,
        },
        errors: {
          ...currentState.errors,
          [action.name]: action.error,
        },
      };
    case ALLERGENS_ALL:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          allergensAll: false,
        },
        errors: {
          ...currentState.errors,
          allergensAll: undefined,
        },
        items: {
          ...currentState.items,
          ...action.allergens,
        },
      };
    case ALLERGENS_ONE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          allergensOne: false,
        },
        errors: {
          ...currentState.errors,
          allergensOne: undefined,
        },
        items: {
          ...currentState.items,
          [action.allergen.id]: {
            ...currentState.items[action.allergen.id],
            ...action.allergen,
          },
        },
      };
    case ALLERGENS_CREATE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          allergensCreate: false,
        },
        errors: {
          ...currentState.errors,
          allergensCreate: undefined,
        },
        items: {
          ...currentState.items,
          [action.allergen.id]: action.allergen,
        },
      };
    case ALLERGENS_UPDATE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          allergensUpdate: false,
        },
        errors: {
          ...currentState.errors,
          allergensUpdate: undefined,
        },
        items: {
          ...currentState.items,
          [action.allergen.id]: {
            ...currentState.items[action.allergen.id],
            ...action.allergen,
          },
        },
      };
    case ALLERGENS_REMOVE:
      newState = {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          allergensRemove: false,
        },
        errors: {
          ...currentState.errors,
          allergensRemove: undefined,
        },
        items: {
          ...currentState.items,
        },
      };
      delete newState.items[action.allergen.id];
      return newState;
    default:
      return currentState;
  }
};

export default allergensReducer;
