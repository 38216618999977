import { USER_ORDERS_DECREMENT_MEALS, USER_ORDERS_FAILURE, USER_ORDERS_GET, USER_ORDERS_INCREMENT_MEALS, USER_ORDERS_LOADING } from '../types/userOrdersTypes';

const INITIAL_STATE = {
  loadings: {},
  errors: {},
  menus: {},
};

const userOrdersReducer = (currentState = INITIAL_STATE, action) => {
  switch (action.type) {
    case USER_ORDERS_LOADING:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: true,
        },
      };
    case USER_ORDERS_FAILURE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: false,
        },
        errors: {
          ...currentState.errors,
          [action.name]: action.error,
        },
      };
    case USER_ORDERS_GET:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          get: false,
        },
        errors: {
          ...currentState.errors,
          get: undefined,
        },
        menus: action.userOrders,
      };
    case USER_ORDERS_INCREMENT_MEALS:
      return {
        ...currentState,
        menus: {
          ...currentState.menus,
          [action.menu.day]: {
            ...currentState.menus[action.menu.day],
            foodCount: currentState.menus[action.menu.day].foodCount + 1,
          },
        },
      };
    case USER_ORDERS_DECREMENT_MEALS:
      return {
        ...currentState,
        menus: {
          ...currentState.menus,
          [action.menu.day]: {
            ...currentState.menus[action.menu.day],
            foodCount:
              currentState.menus[action.menu.day].foodCount > 1
                ? currentState.menus[action.menu.day].foodCount - 1
                : currentState.menus[action.menu.day].foodCount,
          },
        },
      };
    default:
      return currentState;
  }
};

export default userOrdersReducer;
