import React, { useEffect } from 'react';
import 'moment/locale/cs';
import { useDispatch, useSelector } from 'react-redux';
import IdleTimer from 'react-idle-timer';

import PermanentFoodSection from '../../components/Kitchen/PermanentFoodSection';
import TodayMenuSection from '../../components/Kitchen/TodayMenuSection';
import { userLogout } from '../../redux/actions/userActions';

const Kitchen = () => {
  const token = useSelector((state) => state.user.token);

  const dispatch = useDispatch();

  useEffect(() => {
    // připojení komponenty - pokud je někdo přihlášen, odhlásím
    if (token) {
      dispatch(userLogout());
    }
    // odpojení komponenty - odhlásím uživatele
    return () => {
      dispatch(userLogout());
    };
  }, []);

  const onIdle = () => {
    if (token) {
      dispatch(userLogout());
    }
  };

  return (
    <div id="kitchen">
      <IdleTimer element={document} onIdle={onIdle} timeout={10000} />
      <PermanentFoodSection />
      <TodayMenuSection />
    </div>
  );
};

export default Kitchen;
