import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { changeUserCredit } from '../../redux/actions/usersActions';

const CreditModal = (props) => {
  const { show, user, onHide } = props;

  const token = useSelector((state) => state.user.token);
  const loading = useSelector((state) => state.admin.users.loadings.changeCredit);
  const error = useSelector((state) => state.admin.users.errors.changeCredit);

  const dispatch = useDispatch();

  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (isSubmitted && !loading && !error) {
      setIsSubmitted(false);
      onHide();
    }
  }, [loading, error]);

  const handleSubmit = (values) => {
    setIsSubmitted(true);
    dispatch(changeUserCredit(token, user.id, values));
  };

  const initialValues = {
    balance: '',
  };

  const validation = Yup.object().shape({
    balance: Yup.string().required('Částka musí být vyplněna!'),
  });

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Formik initialValues={initialValues} validationSchema={validation} onSubmit={handleSubmit}>
        {({ values, errors, touched, handleChange, handleSubmit }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Změna zůstatku uživatele: {user ? `${user.firstname} ${user.surname} (${user.cardId})` : ''}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group>
                <Form.Label>Částka:</Form.Label>
                <Form.Control
                  type="number"
                  name="balance"
                  placeholder="Částka v Kč"
                  value={values.balance}
                  onChange={handleChange}
                  isInvalid={touched.balance && errors.balance}
                />
                <Form.Control.Feedback type="invalid">{errors.balance}</Form.Control.Feedback>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="light" onClick={onHide}>
                Zavřít
              </Button>
              <Button variant="dark" type="submit" disabled={loading}>
                Provést změnu
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default CreditModal;
