import axios from 'axios';
import { notify } from 'reapop';

import { ORDERS_ALL, ORDERS_DAY, ORDERS_FAILURE, ORDERS_LOADING, ORDERS_ONE } from '../types/ordersTypes';
import { ENDPOINTS, KITCHEN_PASSWORD } from '../../constants';
import { processDayOrders, processOrder, processOrders } from '../helpers/ordersHelpers';
import { ORDERS_REMOVE, ORDERS_UPDATE } from '../types/ordersTypes';
import { getCorrectDate } from '../../utils';

const AOrdersLoading = (name) => ({
  type: ORDERS_LOADING,
  name,
});

const AOrdersFailure = (name, error) => ({
  type: ORDERS_FAILURE,
  name,
  error,
});

const AGetOrders = (orders, activeOrders) => ({
  type: ORDERS_ALL,
  orders,
  activeOrders,
});

const AGetDayOrders = (orders, activeDayOrders) => ({
  type: ORDERS_DAY,
  orders,
  activeDayOrders,
});

const AGetOrder = (order) => ({
  type: ORDERS_ONE,
  order,
});

const AUpdateOrder = (order) => ({
  type: ORDERS_UPDATE,
  order,
});

const ARemoveOrder = (order) => ({
  type: ORDERS_REMOVE,
  order,
});

export const getOrders = (fromDate, toDate) => async (dispatch) => {
  const actionName = 'all';
  dispatch(AOrdersLoading(actionName));

  const from = getCorrectDate(fromDate).toISOString();
  const to = getCorrectDate(toDate).toISOString();

  const config = {
    params: {
      tkn: KITCHEN_PASSWORD,
      from,
      to,
    },
  };

  try {
    const response = await axios.get(ENDPOINTS.orders, config);
    const { data } = response;
    const { orders, activeOrders } = processOrders(data);
    dispatch(AGetOrders(orders, activeOrders));
  } catch (e) {
    dispatch(AOrdersFailure(actionName, e));
    dispatch(
      notify({
        title: 'Získání objednávek',
        message: 'Nepodařilo se získat objednávky',
        status: 'error',
        dismissAfter: 3000,
      }),
    );
  }
};

export const getDayOrders = (day) => async (dispatch) => {
  const actionName = 'day';
  dispatch(AOrdersLoading(actionName));

  const config = {
    params: {
      tkn: KITCHEN_PASSWORD,
      day,
    },
  };

  try {
    const response = await axios.get(ENDPOINTS.orders, config);
    const { data } = response;
    const { orders, activeDayOrders } = processDayOrders(data);
    dispatch(AGetDayOrders(orders, activeDayOrders));
  } catch (e) {
    dispatch(AOrdersFailure(actionName, e));
  }
};

export const getOrder = (token, orderId) => async (dispatch) => {
  const actionName = 'one';
  dispatch(AOrdersLoading(actionName));

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.get(`${ENDPOINTS.orders}/${orderId}`, config);
    const { data } = response;
    const order = processOrder(data);
    dispatch(AGetOrder(order));
  } catch (e) {
    dispatch(AOrdersFailure(actionName, e));
  }
};

export const updateDoneOrder = (token, orderId, done) => async (dispatch) => {
  const actionName = 'update';
  dispatch(AOrdersLoading(actionName));
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.put(`${ENDPOINTS.orders}/${orderId}`, { done: !done, fromKitchen: true }, config);
    const { data } = response;
    dispatch(AUpdateOrder(data));
    dispatch(
      notify({
        title: 'Úprava vyzvednutí objednávky',
        message: `Vyzvednutí objednávky bylo upraveno.`,
        status: 'success',
        dismissAfter: 3000,
      }),
    );
  } catch (e) {
    dispatch(AOrdersFailure(actionName, e));
    const message = e.response.data.message;
    dispatch(
      notify({
        title: 'Úprava vyzvednutí objednávky',
        message: `Vyzvednutí objednávky se nepodařilo upravit. ${message}`,
        status: 'error',
        dismissAfter: 3000,
      }),
    );
  }
};

export const removeOrder = (token, order) => async (dispatch) => {
  const actionName = 'remove';
  dispatch(AOrdersLoading(actionName));
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    await axios.delete(`${ENDPOINTS.orders}/${order.id}`, config);
    dispatch(ARemoveOrder(order));
    dispatch(
      notify({
        title: 'Odstranění objednávky',
        message: `Objednávka byla odstraněna.`,
        status: 'success',
        dismissAfter: 3000,
      }),
    );
  } catch (e) {
    dispatch(AOrdersFailure(actionName, e));
    dispatch(
      notify({
        title: 'Odstranění objednávky',
        message: `Objednávku se nepodařilo odstranit.`,
        status: 'error',
        dismissAfter: 3000,
      }),
    );
  }
};
