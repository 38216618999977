import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Table } from 'react-bootstrap';
import { faHandPointLeft } from '@fortawesome/free-solid-svg-icons/faHandPointLeft';

import GridSection from '../../../components/Section/GridSection';
import MyButton from '../../../components/MyButton/index';
import Section from '../../../components/Section/Section';
import Loading from '../../../components/Loading/index';
import { getRating } from '../../../redux/actions/ratingsActions';

const RatingPreview = () => {
  const match = useRouteMatch();

  const loading = useSelector((state) => state.admin.ratings.loadings.one);
  const rating = useSelector((state) => state.admin.ratings.items[match.params.id]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (match.params.id && !loading) {
      dispatch(getRating(match.params.id));
    }
  }, []);

  if (!rating) return null;

  return (
    <div>
      <GridSection heading="ADMINISTRACE">
        <MyButton link="/admin/ratings" variant="outline-dark" icon={faHandPointLeft} text="ZPĚT" />
      </GridSection>
      <Section heading="NÁHLED HODNOCENÍ">
        {loading ? (
          <Loading />
        ) : (
          <Col xs={{ span: 8, offset: 2 }}>
            <Table responsive striped>
              <tbody>
                <tr>
                  <th>Uživatel:</th>
                  <td>{`${rating.userId.firstname} ${rating.userId.surname}`}</td>
                </tr>
                <tr>
                  <th>Jídlo:</th>
                  <td>{rating.foodId ? rating.foodId.longName : ''}</td>
                </tr>
                <tr>
                  <th>Počet hvězdiček:</th>
                  <td>{rating.stars}</td>
                </tr>
                <tr>
                  <th>Komentář:</th>
                  <td>{rating.comment}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        )}
      </Section>
    </div>
  );
};

export default RatingPreview;
