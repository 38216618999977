import { createLogger } from 'redux-logger';
import { applyMiddleware, createStore } from 'redux';
import reduxThunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import reducers from '../reducers';
import { __DEV__ } from '../../constants';

const middleware = [];
let enhancer;
middleware.push(reduxThunk);

if (__DEV__) {
  const logger = createLogger();
  middleware.push(logger);
  enhancer = composeWithDevTools(applyMiddleware(...middleware));
} else {
  enhancer = applyMiddleware(...middleware);
}

const store = createStore(reducers, enhancer);

export default store;
