const firstPriceIncreaseDate = new Date('2019-09-01');
const secondPriceIncreaseDate = new Date('2020-11-01');
const thirdPriceIncreaseDate = new Date('2021-04-01');
const fourthPriceIncreaseDate = new Date('2023-02-13');

export const priceList = (user, orderDate, foodInfo, numberOfLunch) => {
  let billing = 0;
  switch (foodInfo.type) {
    case 'permanent':
      switch (foodInfo.name) {
        case '+ Snídaně navíc':
          billing += 139;
          break;
        case '+ Oběd navíc':
          if (orderDate < firstPriceIncreaseDate) {
            billing += 109;
          } else if (orderDate < thirdPriceIncreaseDate) {
            billing += 139;
          } else if (orderDate < fourthPriceIncreaseDate) {
            billing += 150;
          } else {
            billing += 189;
          }
          break;
        case '+ Večeře navíc':
          billing += 150;
          break;
        case 'Polévka':
          if (orderDate < thirdPriceIncreaseDate) {
            billing += 30;
          } else {
            billing += 40;
          }
          break;
        case 'Pivo 0,5l':
          billing += 50;
          break;
        case 'Pivo 0,3l':
          if (orderDate < firstPriceIncreaseDate) {
            billing += 25;
          } else {
            billing += 30;
          }
          break;
        case 'Birell 0,3l':
          if (orderDate < thirdPriceIncreaseDate) {
            billing += 20;
          } else {
            billing += 30;
          }
          break;
        case 'Limo 0,33l':
          if (orderDate < secondPriceIncreaseDate) {
            billing += 15;
          } else if (orderDate < thirdPriceIncreaseDate) {
            billing += 20;
          } else {
            billing += 30;
          }
          break;
        case 'Káva':
          if (orderDate < thirdPriceIncreaseDate) {
            billing += 15;
          } else {
            billing += 30;
          }
          break;
        case 'Box':
          billing += 10;
          break;
      }
      break;
    case 'breakfast':
      billing += 139;
      break;
    case 'lunch':
      if (numberOfLunch >= 1) {
        if (orderDate < firstPriceIncreaseDate) {
          billing += 109;
        } else if (orderDate < thirdPriceIncreaseDate) {
          billing += 139;
        } else if (orderDate < fourthPriceIncreaseDate) {
          billing += 150;
        } else {
          billing += 189;
        }
      } else {
        // pro movisio jiná cena
        if (user.cardId.match(/MOV/)) {
          if (orderDate < firstPriceIncreaseDate) {
            billing += 49.05;
          } else if (orderDate < thirdPriceIncreaseDate) {
            billing += 62.55;
          } else if (orderDate < fourthPriceIncreaseDate) {
            billing += 75;
          } else {
            billing += 89;
          }
        } else {
          if (orderDate < firstPriceIncreaseDate) {
            billing += 109;
          } else if (orderDate < thirdPriceIncreaseDate) {
            billing += 139;
          } else if (orderDate < fourthPriceIncreaseDate) {
            billing += 150;
          } else {
            billing += 189;
          }
        }
      }
      break;
    case 'dinner':
      billing += 150;
      break;
  }
  return billing;
};

export const getPackagingPrice = (orderDate) => {
  if (orderDate < thirdPriceIncreaseDate) {
    return 0;
  } else {
    return 10;
  }
};
