import { Button, Col, Form, FormGroup, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Formik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import cs from 'date-fns/locale/cs';
registerLocale('cs', cs);

import { createMenu, updateMenu } from '../../redux/actions/menusActions';
import FoodList from '../../components/FoodList';
import { getCorrectDate } from '../../utils';

import { MenuValidationSchema } from './validation';

const MenuForm = (props) => {
  const { menu, onSubmit } = props;

  const token = useSelector((state) => state.user.token);
  const loadingAdd = useSelector((state) => state.admin.menus.loadings.create);
  const loadingEdit = useSelector((state) => state.admin.menus.loadings.update);

  const dispatch = useDispatch();

  const [addedFoods, setAddedFoods] = useState(menu ? menu.foodIds : []);

  useEffect(() => {
    if (menu) {
      setAddedFoods(menu.foodIds);
    }
  }, [menu]);

  const handleSubmit = (values) => {
    if (menu) {
      dispatch(updateMenu(token, menu.id, values));
    } else {
      dispatch(createMenu(token, values));
    }
    onSubmit();
  };

  const initialValues = {
    day: getCorrectDate(menu ? new Date(menu.day) : new Date()),
    cooking: true,
    foodIds: menu ? menu.foodIds.map((f) => f.id) : [],
  };

  const breakfasts = addedFoods.filter((f) => f.type === 'breakfast');
  const lunches = addedFoods.filter((f) => f.type === 'lunch');
  const dinners = addedFoods.filter((f) => f.type === 'dinner');

  return (
    <Formik initialValues={initialValues} enableReinitialize validationSchema={MenuValidationSchema} onSubmit={handleSubmit}>
      {({ values, errors, setFieldValue, setFieldError, handleSubmit }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Row>
            <Col xs={{ span: 5, offset: 1 }}>
              <Row>
                <Col xs={6}>
                  <Form.Group>
                    <Form.Label>Datum *</Form.Label>
                    <DatePicker
                      className="form-control"
                      selected={values.day}
                      onChange={(value) => {
                        const day = getCorrectDate(value);
                        setFieldError('day', !day ? 'Datum musí být vyplněno!' : undefined);
                        setFieldValue('day', day);
                      }}
                      locale="cs"
                      dateFormat="dd. MM. yyyy"
                    />
                    <Form.Control.Feedback type="invalid">{errors.day}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col className="d-flex flex-row align-items-end">
                  <Form.Group className="mb-4">
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        name="cooking"
                        value={!values.cooking}
                        checked={!values.cooking}
                        onChange={() => setFieldValue('cooking', !values.cooking)}
                      />
                      <Form.Check.Label onClick={() => setFieldValue('cooking', !values.cooking)}>Tento den se nevaří</Form.Check.Label>
                    </Form.Check>
                  </Form.Group>
                </Col>
              </Row>
              {values.cooking ? (
                <FoodList
                  handleAddFood={(food) => {
                    const newFoods = [...addedFoods, food];
                    setAddedFoods(newFoods);
                    setFieldValue(
                      'foodIds',
                      newFoods.map((f) => f.id),
                    );
                  }}
                />
              ) : null}
            </Col>
            <Col xs={{ span: 4, offset: 1 }}>
              <>
                <h4>VAŠE MENU</h4>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Typ</th>
                      <th>Jídlo</th>
                      <th>Možnosti</th>
                    </tr>
                  </thead>
                  <tbody>
                    {breakfasts.length ? (
                      breakfasts.map((food, index) => (
                        <tr key={`row_${food.id}`}>
                          {index === 0 ? <td rowSpan={breakfasts.length}>Snídaně</td> : null}
                          <td>{food.name}</td>
                          <td>
                            <OverlayTrigger placement="right" overlay={<Tooltip id="removeBreakfast">Odebrat snídani</Tooltip>}>
                              <Button
                                variant="danger"
                                onClick={() => {
                                  const newFoods = [...addedFoods];
                                  newFoods.splice(newFoods.indexOf(food), 1);
                                  setAddedFoods(newFoods);
                                  setFieldValue(
                                    'foodIds',
                                    newFoods.map((f) => f.id),
                                  );
                                }}>
                                <FontAwesomeIcon icon={faTrash} />
                              </Button>
                            </OverlayTrigger>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>Snídaně</td>
                        <td colSpan={2} className="text-warning">
                          Menu neobsahuje žádnou snídani!
                        </td>
                      </tr>
                    )}
                    {lunches.length ? (
                      lunches.map((food, index) => (
                        <tr key={`row_${food.id}`}>
                          {index === 0 ? <td rowSpan={lunches.length}>Obědy</td> : null}
                          <td>{food.name}</td>
                          <td>
                            <OverlayTrigger placement="right" overlay={<Tooltip id="removeLunch">Odebrat oběd</Tooltip>}>
                              <Button
                                variant="danger"
                                onClick={() => {
                                  const newFoods = [...addedFoods];
                                  newFoods.splice(newFoods.indexOf(food), 1);
                                  setAddedFoods(newFoods);
                                  setFieldValue(
                                    'foodIds',
                                    newFoods.map((f) => f.id),
                                  );
                                }}>
                                <FontAwesomeIcon icon={faTrash} />
                              </Button>
                            </OverlayTrigger>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>Obědy</td>
                        <td colSpan={2} className="text-warning">
                          Menu neobsahuje žádný oběd!
                        </td>
                      </tr>
                    )}
                    {dinners.length ? (
                      dinners.map((food, index) => (
                        <tr key={`row_${food.id}`}>
                          {index === 0 ? <td rowSpan={dinners.length}>Večeře</td> : null}
                          <td>{food.name}</td>
                          <td>
                            <OverlayTrigger placement="right" overlay={<Tooltip id="removeDinner">Odebrat večeři</Tooltip>}>
                              <Button
                                variant="danger"
                                onClick={() => {
                                  const newFoods = [...addedFoods];
                                  newFoods.splice(newFoods.indexOf(food), 1);
                                  setAddedFoods(newFoods);
                                  setFieldValue(
                                    'foodIds',
                                    newFoods.map((f) => f.id),
                                  );
                                }}>
                                <FontAwesomeIcon icon={faTrash} />
                              </Button>
                            </OverlayTrigger>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>Večeře</td>
                        <td colSpan={2} className="text-warning">
                          Menu neobsahuje žádnou večeři!
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </>
              <FormGroup>
                <Button type="submit" variant="outline-dark" disabled={loadingAdd || loadingEdit}>
                  {menu ? 'Upravit menu' : 'Přidat menu'}
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default MenuForm;
