import React, { useEffect } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { getFoods } from '../../redux/actions/foodsActions';

import FoodListContent from './FoodListContent';

const FoodList = (props) => {
  const { handleAddFood } = props;

  const loading = useSelector((state) => state.admin.foods.loadings.all);
  const foods = useSelector((state) => state.admin.foods.items);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading && !Object.values(foods).length) {
      dispatch(getFoods());
    }
  }, []);

  return (
    <Tabs defaultActiveKey="all">
      <Tab eventKey="all" title="Všechna jídla">
        <FoodListContent type="all" handleAddFood={handleAddFood} />
      </Tab>
      <Tab eventKey="breakfast" title="Snídaně">
        <FoodListContent type="breakfast" handleAddFood={handleAddFood} />
      </Tab>
      <Tab eventKey="lunch" title="Obědy">
        <FoodListContent type="lunch" handleAddFood={handleAddFood} />
      </Tab>
      <Tab eventKey="dinner" title="Večeře">
        <FoodListContent type="dinner" handleAddFood={handleAddFood} />
      </Tab>
    </Tabs>
  );
};

export default FoodList;
