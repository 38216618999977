import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { faHandPointLeft } from '@fortawesome/free-solid-svg-icons/faHandPointLeft';

import GridSection from '../../../components/Section/GridSection';
import Section from '../../../components/Section/Section';
import MyButton from '../../../components/MyButton/index';
import { getUser } from '../../../redux/actions/usersActions';
import UserForm from '../../../forms/UserForms/UserForm';

const UserForms = () => {
  const match = useRouteMatch();
  const history = useHistory();

  const token = useSelector((state) => state.user.token);
  const loading = useSelector((state) => state.admin.users.loadings.one);
  const user = useSelector((state) => state.admin.users.items[match.params.id]);
  const loadingAdd = useSelector((state) => state.admin.users.loadings.create);
  const errorAdd = useSelector((state) => state.admin.users.errors.create);
  const loadingEdit = useSelector((state) => state.admin.users.loadings.update);
  const errorEdit = useSelector((state) => state.admin.users.errors.update);

  const dispatch = useDispatch();

  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (match.params.id && !loading) {
      dispatch(getUser(token, match.params.id));
    }
  }, []);

  useEffect(() => {
    if (isSubmitted && !loadingAdd && !errorAdd) {
      setIsSubmitted(false);
      history.replace('/admin/users');
    }
  }, [loadingAdd, errorAdd]);

  useEffect(() => {
    if (isSubmitted && !loadingEdit && !errorEdit) {
      setIsSubmitted(false);
      history.replace('/admin/users');
    }
  }, [loadingEdit, errorEdit]);

  return (
    <div>
      <GridSection heading="ADMINISTRACE">
        <MyButton link="/admin/users" variant="outline-dark" icon={faHandPointLeft} text="ZPĚT" />
      </GridSection>
      <Section heading={user ? 'Formulář pro úpravu uživatele' : 'Formulář pro přidání uživatele'}>
        <UserForm user={user} onSubmit={() => setIsSubmitted(true)} />
      </Section>
    </div>
  );
};

export default withRouter(UserForms);
