import { combineReducers } from 'redux';
import { reducer as notificationsReducer } from 'reapop';

import userReducer from './userReducer';
import adminReducer from './admin';
import kitchenReducer from './kitchenReducer';
import userOrdersReducer from './userOrdersReducer';

export default combineReducers({
  notifications: notificationsReducer(),
  admin: adminReducer,
  userOrders: userOrdersReducer,
  kitchen: kitchenReducer,
  user: userReducer,
});
