import React, { Component } from 'react';

import './style.scss';

export default class Loading extends Component {
  render() {
    return (
      <div className="lds-css ng-scope">
        <div style={{ width: '100%', height: '100%' }} className="lds-dual-ring">
          <div />
          <div />
        </div>
      </div>
    );
  }
}
