import { KITCHEN_FAILURE, KITCHEN_LOADING, KITCHEN_MENU, KITCHEN_PERMANENT_MENU } from '../types/kitchenTypes';

const INITIAL_STATE = {
  loadings: {},
  errors: {},
};

const kitchenReducer = (currentState = INITIAL_STATE, action) => {
  switch (action.type) {
    case KITCHEN_LOADING:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: true,
        },
      };
    case KITCHEN_FAILURE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: false,
        },
        errors: {
          ...currentState.errors,
          [action.name]: action.error,
        },
      };
    case KITCHEN_PERMANENT_MENU:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          permanentMenu: false,
        },
        errors: {
          ...currentState.errors,
          permanentMenu: undefined,
        },
        permanentMenu: action.permanentMenu,
        soup: action.soup,
      };
    case KITCHEN_MENU:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          menu: false,
        },
        errors: {
          ...currentState.errors,
          menu: undefined,
        },
        menu: action.menu,
      };
    default:
      return currentState;
  }
};

export default kitchenReducer;
