import React from 'react';
import { Route, Switch } from 'react-router-dom';

import FoodsList from './FoodsList';
import FoodPreview from './FoodPreview';
import FoodForms from './FoodForms';

const Foods = () => {
  return (
    <Switch>
      <Route path="/admin/foods/add" component={FoodForms} />
      <Route path="/admin/foods/edit/:id" component={FoodForms} />
      <Route path="/admin/foods/preview/:id" component={FoodPreview} />
      <Route exact path="/admin/foods" component={FoodsList} />
    </Switch>
  );
};

export default Foods;
