import React, { useEffect, useState } from 'react';
import { faHandPointLeft } from '@fortawesome/free-solid-svg-icons/faHandPointLeft';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import GridSection from '../../../components/Section/GridSection';
import Section from '../../../components/Section/Section';
import MyButton from '../../../components/MyButton/index';
import { getAllergen } from '../../../redux/actions/allergensActions';
import AllergenForm from '../../../forms/AllergenForms/AllergenForm';

const AllergenForms = () => {
  const match = useRouteMatch();
  const history = useHistory();

  const loading = useSelector((state) => state.admin.allergens.loadings.allergensOne);
  const allergen = useSelector((state) => state.admin.allergens.items[match.params.id]);
  const loadingAdd = useSelector((state) => state.admin.allergens.loadings.allergensCreate);
  const errorAdd = useSelector((state) => state.admin.allergens.errors.allergensCreate);
  const loadingEdit = useSelector((state) => state.admin.allergens.loadings.allergensUpdate);
  const errorEdit = useSelector((state) => state.admin.allergens.errors.allergensUpdate);

  const dispatch = useDispatch();

  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (match.params.id && !loading) {
      dispatch(getAllergen(match.params.id));
    }
  }, []);

  useEffect(() => {
    if (isSubmitted && !loadingAdd && !errorAdd) {
      setIsSubmitted(false);
      history.replace('/admin/allergens');
    }
  }, [loadingAdd, errorAdd]);

  useEffect(() => {
    if (isSubmitted && !loadingEdit && !errorEdit) {
      setIsSubmitted(false);
      history.replace('/admin/allergens');
    }
  }, [loadingEdit, errorEdit]);

  return (
    <div>
      <GridSection heading="ADMINISTRACE">
        <MyButton variant="outline-dark" icon={faHandPointLeft} text="ZPĚT" action={() => history.goBack()} />
      </GridSection>
      <Section heading={allergen ? 'Formulář pro úpravu alergenu' : 'Formulář pro přidání alergenu'}>
        <AllergenForm allergen={allergen} buttonText={allergen ? 'Upravit alergen' : 'Přidat alergen'} onSubmit={() => setIsSubmitted(true)} />
      </Section>
    </div>
  );
};

export default AllergenForms;
