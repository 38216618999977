import React, { useEffect, useState } from 'react';
import moment from 'moment';
import 'moment/locale/cs';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { faHandPointLeft } from '@fortawesome/free-solid-svg-icons/faHandPointLeft';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { Table } from 'react-bootstrap';

import GridSection from '../../../components/Section/GridSection';
import MyButton from '../../../components/MyButton/index';
import Food from '../../../components/Food/index';
import { getMenu, removeMenu } from '../../../redux/actions/menusActions';
import Section from '../../../components/Section/Section';

const MenuPreview = () => {
  const match = useRouteMatch();
  const history = useHistory();

  const token = useSelector((state) => state.user.token);
  const loading = useSelector((state) => state.admin.menus.loadings.one);
  const menu = useSelector((state) => state.admin.menus.items[match.params.id]);
  const loadingRemove = useSelector((state) => state.admin.menus.loadings.remove);
  const errorRemove = useSelector((state) => state.admin.menus.errors.remove);

  const dispatch = useDispatch();

  const [isRemoved, setIsRemoved] = useState(false);

  useEffect(() => {
    if (!loading) {
      dispatch(getMenu(match.params.id));
    }
  }, []);

  useEffect(() => {
    if (isRemoved && !loadingRemove && !errorRemove) {
      setIsRemoved(false);
      history.goBack();
    }
  }, [loadingRemove, errorRemove]);

  const handleRemove = () => {
    setIsRemoved(true);
    dispatch(removeMenu(token, menu));
  };

  if (!menu) return null;

  const breakfasts = menu.foodIds.filter((f) => f.type === 'breakfast');
  const lunches = menu.foodIds.filter((f) => f.type === 'lunch');
  const dinners = menu.foodIds.filter((f) => f.type === 'dinner');

  return (
    <div>
      <GridSection heading="ADMINISTRACE">
        <MyButton link="/admin/menus" variant="outline-dark" icon={faHandPointLeft} text="ZPĚT" />
        <MyButton link={`/admin/menus/edit/${menu.id}`} variant="outline-dark" icon={faEdit} text="UPRAVIT MENU" />
        <MyButton action={handleRemove} variant="danger" icon={faTrash} text="ODSTRANIT MENU" />
      </GridSection>
      <Section heading={`Menu na ${moment(menu.day).format('dddd D.M.')}`}>
        <Table responsive>
          <tbody>
            <tr>
              <td>Snídaně</td>
              <td>
                {!menu.info
                  ? breakfasts.length
                    ? breakfasts.map((f) => <Food key={`menu_breakfast_${menu.id}_food_${f.id}`} data={f} />)
                    : 'Tento den se nevaří snídaně'
                  : menu.info}
              </td>
            </tr>
            <tr>
              <td>Obědy</td>
              <td>
                {!menu.info
                  ? lunches.length
                    ? lunches.map((f) => <Food key={`menu_lunch_${menu.id}_food_${f.id}`} data={f} />)
                    : 'Tento den se nevaří obědy'
                  : menu.info}
              </td>
            </tr>
            <tr>
              <td>Večeře</td>
              <td>
                {!menu.info
                  ? dinners.length
                    ? dinners.map((f) => <Food key={`menu_dinner_${menu.id}_food_${f.id}`} data={f} />)
                    : 'Tento den se nevaří večeře'
                  : menu.info}
              </td>
            </tr>
          </tbody>
        </Table>
      </Section>
    </div>
  );
};

export default MenuPreview;
