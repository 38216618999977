import { FOODS_ALL, FOODS_CREATE, FOODS_FAILURE, FOODS_LOADING, FOODS_ONE, FOODS_REMOVE, FOODS_UPDATE } from '../../types/foodsTypes';

const INITIAL_STATE = {
  loadings: {},
  errors: {},
  items: {},
};

const foodsReducer = (currentState = INITIAL_STATE, action) => {
  let newState;
  switch (action.type) {
    case FOODS_LOADING:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: true,
        },
      };
    case FOODS_FAILURE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: false,
        },
        errors: {
          ...currentState.errors,
          [action.name]: action.error,
        },
      };
    case FOODS_ALL:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          all: false,
        },
        errors: {
          ...currentState.errors,
          all: undefined,
        },
        items: action.foods,
      };
    case FOODS_ONE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          one: false,
        },
        errors: {
          ...currentState.errors,
          one: undefined,
        },
        items: {
          ...currentState.items,
          [action.food.id]: action.food,
        },
      };
    case FOODS_CREATE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          create: false,
        },
        errors: {
          ...currentState.errors,
          create: undefined,
        },
        items: {
          ...currentState.items,
          [action.food.id]: action.food,
        },
      };
    case FOODS_UPDATE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          update: false,
        },
        errors: {
          ...currentState.errors,
          update: undefined,
        },
        items: {
          ...currentState.items,
          [action.food.id]: {
            ...currentState.items[action.food.id],
            ...action.food,
          },
        },
      };
    case FOODS_REMOVE:
      newState = {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          remove: false,
        },
        errors: {
          ...currentState.errors,
          remove: undefined,
        },
        items: {
          ...currentState.items,
        },
      };
      delete newState.items[action.food.id];
      return newState;
    default:
      return currentState;
  }
};

export default foodsReducer;
