import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import Orders from '../Orders';
import Login from '../Login';

const Root = () => {
  const token = useSelector((state) => state.user.token);

  if (!token) {
    return <Route name="/" component={Login} />;
  }

  return (
    <Switch>
      <Route name="/" component={Orders} />
    </Switch>
  );
};

export default Root;
