import axios from 'axios';
import { notify } from 'reapop';

import { ENDPOINTS } from '../../constants';
import { RATINGS_ALL, RATINGS_FAILURE, RATINGS_LOADING, RATINGS_ONE } from '../types/ratingsTypes';
import { processRatings } from '../helpers/ratingsHelpers';

const ARatingsLoading = (name) => ({
  type: RATINGS_LOADING,
  name,
});

const ARatingsFailure = (name, error) => ({
  type: RATINGS_FAILURE,
  name,
  error,
});

const AGetRatings = (ratings) => ({
  type: RATINGS_ALL,
  ratings,
});

const AGetRating = (rating) => ({
  type: RATINGS_ONE,
  rating,
});

export const getRatings = () => async (dispatch) => {
  const actionName = 'all';
  try {
    dispatch(ARatingsLoading(actionName));
    const response = await axios.get(ENDPOINTS.ratings);
    const { data } = response;
    const ratings = processRatings(data);
    dispatch(AGetRatings(ratings));
  } catch (e) {
    dispatch(ARatingsFailure(actionName, e));
    dispatch(
      notify({
        title: 'Získání hodnocení',
        message: 'Nepodařilo se získat seznam hodnocení',
        status: 'error',
        dismissAfter: 3000,
      }),
    );
  }
};

export const getRating = (ratingId) => async (dispatch) => {
  const actionName = 'one';
  dispatch(ARatingsLoading(actionName));
  try {
    const response = await axios.get(`${ENDPOINTS.ratings}/${ratingId}`);
    const { data } = response;
    dispatch(AGetRating(data));
  } catch (e) {
    dispatch(ARatingsFailure(actionName, e));
    dispatch(
      notify({
        title: 'Získání hodnocení',
        message: 'Nepodařilo se získat informace o hodnocení',
        status: 'error',
        dismissAfter: 3000,
      }),
    );
  }
};
