import moment from 'moment';
import axios from 'axios';
import { notify } from 'reapop';

import { ENDPOINTS, KITCHEN_PASSWORD } from '../../constants';
import { processUserOrders } from '../helpers/userOrdersHelpers';
import { USER_ORDERS_DECREMENT_MEALS, USER_ORDERS_FAILURE, USER_ORDERS_GET, USER_ORDERS_INCREMENT_MEALS, USER_ORDERS_LOADING } from '../types/userOrdersTypes';
import { getCorrectDate } from '../../utils';

const AUserOrdersLoading = (name) => ({
  type: USER_ORDERS_LOADING,
  name,
});

const AUserOrdersFailure = (name, error) => ({
  type: USER_ORDERS_FAILURE,
  name,
  error,
});

const AUserOrders = (userOrders) => ({
  type: USER_ORDERS_GET,
  userOrders,
});

export const AIncrementMeals = (menu) => ({
  type: USER_ORDERS_INCREMENT_MEALS,
  menu,
});

export const ADecrementMeals = (menu) => ({
  type: USER_ORDERS_DECREMENT_MEALS,
  menu,
});

export const getUserOrders = (userId) => async (dispatch) => {
  const actionName = 'get';
  dispatch(AUserOrdersLoading(actionName));
  const from = getCorrectDate(moment().startOf('day')).toISOString();
  const to = getCorrectDate(moment().add(1, 'week').endOf('week')).toISOString();
  try {
    const menuConfig = {
      params: {
        from,
        to,
        sort: 'day',
      },
    };
    const menusResponse = await axios.get(ENDPOINTS.menus, menuConfig);
    const orderConfig = {
      params: {
        tkn: KITCHEN_PASSWORD,
        from,
        to,
        userId,
      },
    };
    try {
      const ordersResponse = await axios.get(ENDPOINTS.orders, orderConfig);
      const userOrders = processUserOrders(menusResponse.data, ordersResponse.data);
      dispatch(AUserOrders(userOrders));
    } catch (e) {
      const userOrders = processUserOrders(menusResponse.data);
      dispatch(AUserOrders(userOrders));
      dispatch(
        notify({
          title: 'Nepodařilo se načíst objednávky uživatele',
          message: e.response.data.message,
          status: 'warning',
          dismissAfter: 3000,
        }),
      );
    }
  } catch (e) {
    dispatch(AUserOrdersFailure(actionName, e));
    dispatch(
      notify({
        title: 'Nepodařilo se načíst jídelní lístek',
        message: e.response.data.message,
        status: 'error',
        dismissAfter: 3000,
      }),
    );
  }
};

export const makeOrder = (token, userId, menu, food, takeout) => async (dispatch) => {
  const order = menu.order;

  let foods = [];
  if (order) {
    foods = order.foodInfo.map((f) => ({ foodId: f.id, takeout: f.takeout }));
    foods.push({ foodId: food.id, takeout });
  } else {
    foods.push({ foodId: food.id, takeout });
  }

  const values = {
    day: menu.day,
    userId,
    foodIds: foods,
    emailed: false,
  };

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  if (order) {
    try {
      await axios.put(`${ENDPOINTS.orders}/${order.id}`, values, config);
      dispatch(getUserOrders(userId));
      dispatch(
        notify({
          title: 'Objednáno',
          message: food.longName,
          status: 'success',
          dismissAfter: 3000,
        }),
      );
    } catch (e) {
      dispatch(
        notify({
          title: 'Nepodařilo se objednat jídlo',
          message: e.response.data.message,
          status: 'error',
          dismissAfter: 3000,
        }),
      );
    }
  } else {
    try {
      await axios.post(ENDPOINTS.orders, values, config);
      dispatch(getUserOrders(userId));
      dispatch(
        notify({
          title: 'Objednáno',
          message: food.longName,
          status: 'success',
          dismissAfter: 3000,
        }),
      );
    } catch (e) {
      dispatch(
        notify({
          title: 'Nepodařilo se objednat jídlo',
          message: e.response.data.message,
          status: 'error',
          dismissAfter: 3000,
        }),
      );
    }
  }
};

export const cancelOrder = (token, userId, menu, food) => async (dispatch) => {
  const order = menu.order;

  if (order) {
    const foods = order.foodInfo.map((f) => ({ foodId: f.id, takeout: f.takeout }));
    const index = foods.findIndex((f) => f.foodId === food.id);
    foods.splice(index, 1);

    const values = {
      day: menu.day,
      userId: userId,
      foodIds: foods,
      emailed: false,
    };

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    if (foods.length > 0) {
      try {
        await axios.put(`${ENDPOINTS.orders}/${order.id}`, values, config);
        dispatch(getUserOrders(userId));
        dispatch(
          notify({
            title: 'Jídlo odebráno z objednávky',
            message: food.longName,
            status: 'success',
            dismissAfter: 3000,
          }),
        );
      } catch (e) {
        dispatch(
          notify({
            title: 'Nepodařilo se zrušit jídlo',
            message: e.response.data.message,
            status: 'error',
            dismissAfter: 3000,
          }),
        );
      }
    } else {
      try {
        await axios.delete(`${ENDPOINTS.orders}/${order.id}`, config);
        dispatch(getUserOrders(userId));
        dispatch(
          notify({
            title: 'Jídlo odebráno z objednávky',
            message: food.longName,
            status: 'success',
            dismissAfter: 3000,
          }),
        );
      } catch (e) {
        dispatch(
          notify({
            title: 'Nepodařilo se zrušit jídlo',
            message: e.response.data.message,
            status: 'error',
            dismissAfter: 3000,
          }),
        );
      }
    }
  }
};
