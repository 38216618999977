import axios from 'axios';
import { notify } from 'reapop';

import { ENDPOINTS, KITCHEN_PASSWORD } from '../../constants';
import { KITCHEN_FAILURE, KITCHEN_LOADING, KITCHEN_MENU, KITCHEN_PERMANENT_MENU } from '../types/kitchenTypes';
import { processMenu, processPermanentMenu } from '../helpers/kitchenHelpers';
import { getCorrectDate } from '../../utils';

const AKitchenLoading = (name) => ({
  type: KITCHEN_LOADING,
  name,
});

const AKitchenFailure = (name, error) => ({
  type: KITCHEN_FAILURE,
  name,
  error,
});

const APermanentMenu = (permanentMenu, soup) => ({
  type: KITCHEN_PERMANENT_MENU,
  permanentMenu,
  soup,
});

const AMenu = (menu) => ({
  type: KITCHEN_MENU,
  menu,
});

export const getPermanentMenu = () => async (dispatch) => {
  const actionName = 'permanentMenu';
  dispatch(AKitchenLoading(actionName));
  const config = {
    params: {
      onlyPermanent: true,
    },
  };
  try {
    const response = await axios.get(ENDPOINTS.foods, config);
    const { permanentMenu, soup } = processPermanentMenu(response.data);
    dispatch(APermanentMenu(permanentMenu, soup));
  } catch (e) {
    dispatch(AKitchenFailure(actionName, e));
    dispatch(
      notify({
        title: 'Nepodařilo se načíst stálou nabídku',
        message: e.response.data.message,
        status: 'error',
        dismissAfter: 3000,
      }),
    );
  }
};

export const getMenu = (userId) => async (dispatch) => {
  const actionName = 'menu';
  dispatch(AKitchenLoading(actionName));
  try {
    const menuResponse = await axios.get(`${ENDPOINTS.menus}/today`);
    try {
      const config = {
        params: {
          tkn: KITCHEN_PASSWORD,
          day: menuResponse.data.day,
        },
      };
      const allOrdersResponse = await axios.get(ENDPOINTS.orders, config);
      const menu = processMenu(menuResponse.data, allOrdersResponse.data, userId);
      dispatch(AMenu(menu));
    } catch (e) {
      const menu = processMenu(menuResponse.data);
      dispatch(AMenu(menu));
    }
  } catch (e) {
    dispatch(AKitchenFailure(actionName, e));
    dispatch(
      notify({
        title: 'Nepodařilo se načíst menu',
        message: e.response.data.message,
        status: 'error',
        dismissAfter: 3000,
      }),
    );
  }
};

export const orderPermanentFood = (token, userId, menu, food, takeout) => async (dispatch) => {
  const order = menu ? menu.order : undefined;

  let foods = [];
  if (order) {
    foods = order.foodInfo.map((f) => ({ foodId: f.id, takeout: f.takeout }));
    foods.push({ foodId: food.id, takeout });
  } else {
    foods.push({ foodId: food.id, takeout });
  }

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const date = new Date();
  const breakfastStart = new Date().setHours(0, 0, 0, 0);
  const breakfastEnd = new Date().setHours(11, 0, 0, 0);
  const lunchStart = new Date().setHours(11, 0, 0, 0);
  const lunchEnd = new Date().setHours(15, 0, 0, 0);
  const dinnerStart = new Date().setHours(15, 0, 0, 0);
  const dinnerEnd = new Date().setHours(24, 0, 0, 0);

  let type;
  if (breakfastStart < date && date < breakfastEnd) {
    type = 'breakfast';
  } else if (lunchStart < date && date < lunchEnd) {
    type = 'lunch';
  } else if (dinnerStart < date && date < dinnerEnd) {
    type = 'dinner';
  }

  const breakfasts = order ? order.foodInfo.filter((f) => f.type === 'breakfast') : [];
  const lunches = order ? order.foodInfo.filter((f) => f.type === 'lunch') : [];
  const dinners = order ? order.foodInfo.filter((f) => f.type === 'dinner') : [];

  const breakfastPickedUp = type === 'breakfast' ? true : !breakfasts.length ? true : order.breakfastPickedUp;
  const lunchPickedUp = type === 'lunch' ? true : !lunches.length ? true : order.lunchPickedUp;
  const dinnerPickedUp = type === 'dinner' ? true : !dinners.length ? true : order.dinnerPickedUp;

  const values = {
    day: menu ? menu.day : getCorrectDate(new Date()),
    userId: userId,
    foodIds: foods,
    fromKitchen: true,
    breakfastPickedUp,
    lunchPickedUp,
    dinnerPickedUp,
    done: breakfastPickedUp && lunchPickedUp && dinnerPickedUp,
  };

  if (order) {
    try {
      await axios.put(`${ENDPOINTS.orders}/${order.id}`, values, config);
      dispatch(getMenu(userId));
    } catch (e) {
      dispatch(
        notify({
          title: 'Nepodařilo se objednat ze stálé nabídky',
          message: e.response.data.message,
          status: 'error',
          dismissAfter: 3000,
        }),
      );
    }
  } else {
    try {
      await axios.post(ENDPOINTS.orders, values, config);
      dispatch(getMenu(userId));
    } catch (e) {
      dispatch(
        notify({
          title: 'Nepodařilo se objednat ze stálé nabídky',
          message: e.response.data.message,
          status: 'error',
          dismissAfter: 3000,
        }),
      );
    }
  }
};
