import React from 'react';
import { Route, Switch } from 'react-router-dom';

import MenusList from './MenusList';
import MenuPreview from './MenuPreview';
import MenuForms from './MenuForms';

const Menus = () => {
  return (
    <Switch>
      <Route path="/admin/menus/add" component={MenuForms} />
      <Route path="/admin/menus/edit/:id" component={MenuForms} />
      <Route path="/admin/menus/preview/:id" component={MenuPreview} />
      <Route path="/admin/menus" component={MenusList} />
    </Switch>
  );
};

export default Menus;
