import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { faHandPointLeft } from '@fortawesome/free-solid-svg-icons/faHandPointLeft';

import GridSection from '../../../components/Section/GridSection';
import Section from '../../../components/Section/Section';
import MyButton from '../../../components/MyButton/index';
import { getOrder } from '../../../redux/actions/ordersActions';
import OrderPickupForm from '../../../forms/OrderPickupForm';
import Loading from '../../../components/Loading';

const OrderChangePickupForm = () => {
  const match = useRouteMatch();
  const history = useHistory();

  const token = useSelector((state) => state.user.token);
  const loading = useSelector((state) => state.admin.orders.loadings.one);
  const order = useSelector((state) => state.admin.orders.items[match.params.id]);
  const loadingEdit = useSelector((state) => state.admin.orders.loadings.update);
  const errorEdit = useSelector((state) => state.admin.orders.errors.update);

  const dispatch = useDispatch();

  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (match.params.id && !loading) {
      dispatch(getOrder(token, match.params.id));
    }
  }, []);

  useEffect(() => {
    if (isSubmitted && !loadingEdit && !errorEdit) {
      setIsSubmitted(false);
      history.replace(`/admin/orders/dayPreview/${order.day}`);
    }
  }, [loadingEdit, errorEdit]);

  if (!order) {
    if (!loading) {
      return null;
    } else {
      return (
        <div>
          <Loading />
        </div>
      );
    }
  }

  return (
    <div>
      <GridSection heading="ADMINISTRACE">
        <MyButton link={`/admin/orders/dayPreview/${order.day}`} variant="outline-dark" icon={faHandPointLeft} text="ZPĚT" />
      </GridSection>
      <Section heading="Formulář pro úpravu vyzvednutí">
        <OrderPickupForm order={order} onSubmit={() => setIsSubmitted(true)} />
      </Section>
    </div>
  );
};

export default OrderChangePickupForm;
