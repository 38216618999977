import React from 'react';

import FoodTableAll from './FoodTableAll';
import FoodTablePermanent from './FoodTablePermanent';
import FoodTableByType from './FoodTableByType';

const FoodTable = (props) => {
  const { type } = props;

  switch (type) {
    case 'all':
      return <FoodTableAll />;
    case 'permanent':
      return <FoodTablePermanent />;
    default:
      return <FoodTableByType type={type} />;
  }
};

export default FoodTable;
