import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { faHandPointLeft } from '@fortawesome/free-solid-svg-icons/faHandPointLeft';

import GridSection from '../../../components/Section/GridSection';
import Section from '../../../components/Section/Section';
import MyButton from '../../../components/MyButton/index';
import { getFood } from '../../../redux/actions/foodsActions';
import FoodForm from '../../../forms/FoodForms/FoodForm';

const FoodForms = () => {
  const match = useRouteMatch();
  const history = useHistory();

  const loading = useSelector((state) => state.admin.foods.loadings.one);
  const food = useSelector((state) => state.admin.foods.items[match.params.id]);
  const loadingAdd = useSelector((state) => state.admin.foods.loadings.create);
  const errorAdd = useSelector((state) => state.admin.foods.errors.create);
  const loadingEdit = useSelector((state) => state.admin.foods.loadings.update);
  const errorEdit = useSelector((state) => state.admin.foods.errors.update);

  const dispatch = useDispatch();

  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (match.params.id && !loading) {
      dispatch(getFood(match.params.id));
    }
  }, []);

  useEffect(() => {
    if (isSubmitted && !loadingAdd && !errorAdd) {
      setIsSubmitted(false);
      history.replace('/admin/foods');
    }
  }, [loadingAdd, errorAdd]);

  useEffect(() => {
    if (isSubmitted && !loadingEdit && !errorEdit) {
      setIsSubmitted(false);
      history.replace('/admin/foods');
    }
  }, [loadingEdit, errorEdit]);

  return (
    <div>
      <GridSection heading="ADMINISTRACE">
        <MyButton link="/admin/foods" variant="outline-dark" icon={faHandPointLeft} text="ZPĚT" />
      </GridSection>
      <Section heading={food ? 'Formulář pro úpravu jídla' : 'Formulář pro přidání jídla'}>
        <FoodForm food={food} onSubmit={() => setIsSubmitted(true)} />
      </Section>
    </div>
  );
};

export default FoodForms;
