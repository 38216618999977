import { MENUS_ALL, MENUS_CREATE, MENUS_FAILURE, MENUS_LOADING, MENUS_ONE, MENUS_REMOVE, MENUS_UPDATE } from '../../types/menusTypes';

const INITIAL_STATE = {
  loadings: {},
  errors: {},
  items: {},
};

const menusReducer = (currentState = INITIAL_STATE, action) => {
  let newState;
  switch (action.type) {
    case MENUS_LOADING:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: true,
        },
      };
    case MENUS_FAILURE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: false,
        },
        errors: {
          ...currentState.errors,
          [action.name]: action.error,
        },
      };
    case MENUS_ALL:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          all: false,
        },
        errors: {
          ...currentState.errors,
          all: undefined,
        },
        items: action.menus,
      };
    case MENUS_ONE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          one: false,
        },
        errors: {
          ...currentState.errors,
          one: undefined,
        },
        items: {
          ...currentState.items,
          [action.menu.id]: action.menu,
        },
      };
    case MENUS_CREATE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          create: false,
        },
        errors: {
          ...currentState.errors,
          create: undefined,
        },
        items: {
          ...currentState.items,
          [action.menu.id]: action.menu,
        },
      };
    case MENUS_UPDATE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          update: false,
        },
        errors: {
          ...currentState.errors,
          update: undefined,
        },
        items: {
          ...currentState.items,
          [action.menu.id]: {
            ...currentState.items[action.menu.id],
            ...action.menu,
          },
        },
      };
    case MENUS_REMOVE:
      newState = {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          remove: false,
        },
        errors: {
          ...currentState.errors,
          remove: undefined,
        },
        items: {
          ...currentState.items,
        },
      };
      delete newState.items[action.menu.id];
      return newState;
    default:
      return currentState;
  }
};

export default menusReducer;
