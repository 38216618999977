import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import jsPDF from 'jspdf';
import { remove as removeDiacritics } from 'diacritics';
import moment from 'moment';
import { notify } from 'reapop';

import { ENDPOINTS } from '../../constants';

const PrintOrderButton = () => {
  const token = useSelector((state) => state.user.token);
  const menu = useSelector((state) => state.kitchen.menu);

  const dispatch = useDispatch();

  const date = new Date();
  const breakfastStart = new Date().setHours(0, 0, 0, 0);
  const breakfastEnd = new Date().setHours(11, 0, 0, 0);
  const lunchStart = new Date().setHours(11, 0, 0, 0);
  const lunchEnd = new Date().setHours(15, 0, 0, 0);
  const dinnerStart = new Date().setHours(15, 0, 0, 0);
  const dinnerEnd = new Date().setHours(24, 0, 0, 0);

  let type;
  if (breakfastStart < date && date < breakfastEnd) {
    type = 'breakfast';
  } else if (lunchStart < date && date < lunchEnd) {
    type = 'lunch';
  } else if (dinnerStart < date && date < dinnerEnd) {
    type = 'dinner';
  }

  // první tisk objednávky po přihlášení
  useEffect(() => {
    if (token && menu && menu.order && !menu.order.done) {
      handlePrintOrder(false);
    }
  }, [token, menu]);

  const handlePrintOrder = async (force = false) => {
    if (menu && menu.order) {
      const order = menu.order;

      if (!force) {
        if (type === 'breakfast' && order.breakfastPickedUp) return;
        if (type === 'lunch' && order.lunchPickedUp) return;
        if (type === 'dinner' && order.dinnerPickedUp) return;
      }

      const foods = order.foodInfo.filter((f) => f.type === type);
      const breakfasts = menu.order.foodInfo.filter((f) => f.type === 'breakfast');
      const lunches = menu.order.foodInfo.filter((f) => f.type === 'lunch');
      const dinners = menu.order.foodInfo.filter((f) => f.type === 'dinner');

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const breakfastPickedUp = type === 'breakfast' ? true : !breakfasts.length ? true : order.breakfastPickedUp;
      const lunchPickedUp = type === 'lunch' ? true : !lunches.length ? true : order.lunchPickedUp;
      const dinnerPickedUp = type === 'dinner' ? true : !dinners.length ? true : order.dinnerPickedUp;

      const values = {
        breakfastPickedUp,
        lunchPickedUp,
        dinnerPickedUp,
        done: breakfastPickedUp && lunchPickedUp && dinnerPickedUp,
        emailed: true,
        fromKitchen: true,
      };

      try {
        await axios.put(`${ENDPOINTS.orders}/${order.id}`, values, config);
        const doc = new jsPDF('landscape', 'mm', 'a7');
        foods.forEach((food, index) => {
          const f = menu.foods[food.id];
          if (!order.done) f.pickedCount += 1;
          if (index > 0) doc.addPage();
          doc.setFontSize(20);
          doc.text(removeDiacritics(`${order.userId.firstname} ${order.userId.surname}`), 52, 10, 'center');
          doc.text(`${f.pickedCount}/${f.totalCount}`, 52, 20, 'center');
          doc.text(removeDiacritics(food.name), 52, 30, 'center');
          doc.setFontSize(14);
          if (food.takeout) {
            doc.setFontSize(16);
            doc.setFontType('bold');
            doc.text('Jidlo s sebou', 52, 40, 'center');
            doc.setFontSize(14);
            doc.setFontType('normal');
            doc.text(`Cislo objednavky: ${order.orderNumber}`, 52, 50, 'center');
            doc.setFontSize(10);
            doc.text(removeDiacritics(moment().format('LLL')), 52, 60, 'center');
            doc.text('----------', 52, 70, 'center');
          } else {
            doc.text(`Cislo objednavky: ${order.orderNumber}`, 52, 40, 'center');
            doc.setFontSize(10);
            doc.text(removeDiacritics(moment().format('LLL')), 52, 50, 'center');
            doc.text('----------', 52, 60, 'center');
          }
        });
        doc.autoPrint();
        const w = window.open(doc.output('bloburl'), '_blank');
        const interval = setInterval(() => {
          if (w) {
            w.close();
          } else {
            clearInterval(interval);
          }
        }, 5000);
      } catch (e) {
        dispatch(
          notify({
            title: 'Objednávku se nepodařilo vyzvednout',
            message: 'Zkuste se znovu přihlásit',
            status: 'error',
            dismissAfter: 3000,
          }),
        );
      }
    }
  };

  if (!menu || !menu.order) return null;

  const breakfasts = menu.order.foodInfo.filter((f) => f.type === 'breakfast');
  const lunches = menu.order.foodInfo.filter((f) => f.type === 'lunch');
  const dinners = menu.order.foodInfo.filter((f) => f.type === 'dinner');

  if (type === 'breakfast' && !breakfasts.length) return null;
  if (type === 'lunch' && !lunches.length) return null;
  if (type === 'dinner' && !dinners.length) return null;

  return (
    <Button className="btn-big" onClick={() => handlePrintOrder(true)}>
      TISK
    </Button>
  );
};

export default PrintOrderButton;
