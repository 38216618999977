import React, { useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import jsPDF from 'jspdf';
import { remove as removeDiacritics } from 'diacritics';
import moment from 'moment';

import { getMenu, orderPermanentFood } from '../../redux/actions/kitchenActions';
import { userLogout } from '../../redux/actions/userActions';
import Food from '../Food';

import Chip from './Chip';
import PrintOrderButton from './PrintOrderButton';

const TodayMenuSection = () => {
  const token = useSelector((state) => state.user.token);
  const user = useSelector((state) => state.user.user);

  const loading = useSelector((state) => state.kitchen.loadings.menu);
  const menu = useSelector((state) => state.kitchen.menu);
  const soup = useSelector((state) => state.kitchen.soup);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading) {
      dispatch(getMenu(user ? user.id : undefined));
    }
  }, [token]);

  const handleLogout = async () => {
    dispatch(userLogout());
  };

  const handleOrderPermanentFood = async (food, takeout = false) => {
    if (user) {
      dispatch(orderPermanentFood(token, user.id, menu, food, takeout));
      handlePrintPermanentOrder(food);
    }
  };

  const handlePrintPermanentOrder = (food) => {
    const doc = new jsPDF('landscape', 'mm', 'a7');
    doc.setFont('times');
    doc.setFontSize(20);
    doc.text(removeDiacritics(`${user.firstname} ${user.surname}`), 52, 10, 'center');
    doc.text(removeDiacritics(food.name), 52, 20, 'center');
    doc.setFontSize(10);
    doc.text(removeDiacritics(moment().format('LLL')), 52, 30, 'center');
    doc.text('----------', 52, 70, 'center');
    doc.autoPrint();
    const w = window.open(doc.output('bloburl'), '_blank');
    const interval = setInterval(() => {
      if (w) {
        w.close();
      } else {
        clearInterval(interval);
      }
    }, 5000);
  };

  const date = new Date();
  const breakfastStart = new Date().setHours(0, 0, 0, 0);
  const breakfastEnd = new Date().setHours(11, 0, 0, 0);
  const lunchStart = new Date().setHours(11, 0, 0, 0);
  const lunchEnd = new Date().setHours(15, 0, 0, 0);
  const dinnerStart = new Date().setHours(15, 0, 0, 0);
  const dinnerEnd = new Date().setHours(24, 0, 0, 0);

  let foods = [];
  if (menu && menu.foodIds.length) {
    if (breakfastStart < date && date < breakfastEnd) {
      foods = menu.foodIds.filter((f) => f.type === 'breakfast');
    } else if (lunchStart < date && date < lunchEnd) {
      foods = menu.foodIds.filter((f) => f.type === 'lunch');
      if (soup) {
        foods = [soup, ...foods];
      }
    } else if (dinnerStart < date && date < dinnerEnd) {
      foods = menu.foodIds.filter((f) => f.type === 'dinner');
    }
  }

  const rows = [];
  const firstRow = foods.slice(0, 3);
  let i = 3;
  while (i < 5) {
    rows.push(foods.slice(i, i + 5));
    i += 5;
  }

  const renderFood = (menu, food) => {
    const orderedFoodCount = menu.order ? menu.order.foodInfo.filter((item) => item.id === food.id).length : 0;
    const takeoutCount = menu.order ? menu.order.foodInfo.filter((item) => item.id === food.id && item.takeout === true).length : 0;
    const onsiteCount = menu.order ? menu.order.foodInfo.filter((item) => item.id === food.id && item.takeout === false).length : 0;

    const takeoutPickedCount = menu.foods[food.id] ? menu.foods[food.id].takeoutPickedCount : 0;
    const takeoutTotalCount = menu.foods[food.id] ? menu.foods[food.id].takeoutTotalCount : 0;
    const nonTakeoutPickedCount = menu.foods[food.id] ? menu.foods[food.id].nonTakeoutPickedCount : 0;
    const nonTakeoutTotalCount = menu.foods[food.id] ? menu.foods[food.id].nonTakeoutTotalCount : 0;

    const disabled = !user;

    if (food === soup) {
      return (
        <Food
          key={`permanentFood_${soup.id}`}
          disabled={disabled}
          data={soup}
          ordered={soup.ordered}
          orderedCount={menu.order ? menu.order.foodInfo.filter((item) => item.id === soup.id).length : 0}
          action={!disabled ? () => handleOrderPermanentFood(food, false) : null}
        />
      );
    }

    return (
      <Food
        key={`menuFood_${food.id}`}
        disabled={disabled}
        data={food}
        ordered={orderedFoodCount > 0}
        onsiteCount={onsiteCount}
        takeoutCount={takeoutCount}
        takeoutPickedCount={disabled ? `${takeoutPickedCount}/${takeoutTotalCount}` : null}
        nontakeoutPickedCount={disabled ? `${nonTakeoutPickedCount}/${nonTakeoutTotalCount}` : null}
      />
    );
  };

  return (
    <div className="section">
      <h1>DNEŠNÍ NABÍDKA</h1>
      <Row key="row_firstRow" className="d-row">
        {firstRow.map((food, colIndex) => (
          <Col key={`row_firstRow_col_${colIndex}`}>{renderFood(menu, food)}</Col>
        ))}
        {firstRow.length < 3 ? [...new Array(3 - firstRow.length)].map((v, i) => <Col key={`col_${i}`} />) : null}
        <Col className="d-flex flex-column justify-content-between align-items-end">
          <Button className="btn-big" onClick={handleLogout}>
            ODHLÁSIT
          </Button>
          <PrintOrderButton />
        </Col>
        <Col className="justify-content-end align-items-center">{<Chip />}</Col>
      </Row>
      {rows.map((row, rowIndex) => (
        <Row key={`row_${rowIndex}`} className="d-row">
          {row.map((food, colIndex) => (
            <Col key={`row_${rowIndex}_col_${colIndex}`}>{renderFood(menu, food)}</Col>
          ))}
          {row.length < 5 && [...new Array(5 - row.length)].map((v, i) => <Col key={`col_${i}`} />)}
        </Row>
      ))}
    </div>
  );
};

export default TodayMenuSection;
