import { Button, Form } from 'react-bootstrap';
import React, { useEffect, useRef } from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { userLogin } from '../../redux/actions/userActions';

import { UserLoginValidationSchema } from './validation';

const UserLoginForm = (props) => {
  const { onSubmit } = props;

  const loading = useSelector((state) => state.user.loadings.userLogin);

  const dispatch = useDispatch();

  const refInput = useRef();

  useEffect(() => {
    if (refInput && refInput.current) {
      refInput.current.focus();
    }
  }, []);

  const handleSubmit = (values) => {
    dispatch(userLogin(values));
    onSubmit();
  };

  return (
    <Formik initialValues={{ cardId: '' }} validationSchema={UserLoginValidationSchema} onSubmit={handleSubmit}>
      {({ values, errors, touched, handleChange, handleSubmit }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group controlId="cardId">
            <Form.Control
              ref={refInput}
              type="password"
              name="cardId"
              value={values.cardId}
              placeholder="123 456"
              onChange={handleChange}
              isInvalid={touched.cardId && errors.cardId}
            />
            <Form.Control.Feedback type="invalid">{errors.cardId}</Form.Control.Feedback>
          </Form.Group>
          <Button disabled={loading} className="btn btn-login" type="submit">
            PŘIHLÁSIT
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default UserLoginForm;
