import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { faHandPointLeft } from '@fortawesome/free-solid-svg-icons/faHandPointLeft';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-regular-svg-icons/faStar';
import { faStar as faStarFilled } from '@fortawesome/free-solid-svg-icons/faStar';
import { faStarHalfAlt as faStarHalf } from '@fortawesome/free-solid-svg-icons/faStarHalfAlt';
import { useHistory } from 'react-router-dom';
import { remove as removeDiacritics } from 'diacritics';

import GridSection from '../../../components/Section/GridSection';
import MyButton from '../../../components/MyButton/index';
import Loading from '../../../components/Loading/index';
import { getRatings } from '../../../redux/actions/ratingsActions';
import Section from '../../../components/Section/Section';
import RatingFilter from '../../../components/Filters/RatingFilter';

const RatingsList = () => {
  const history = useHistory();

  const loading = useSelector((state) => state.admin.ratings.loadings.all);
  const ratings = useSelector((state) => state.admin.ratings.items);
  const ratingsArray = Object.values(ratings);

  const dispatch = useDispatch();

  const [filteredRatings, setFilteredRatings] = useState(ratingsArray);

  useEffect(() => {
    if (!loading) {
      dispatch(getRatings());
    }
  }, []);

  useEffect(() => {
    handleFilter('');
  }, [ratings]);

  const handleFilter = (search) => {
    const searchedText = removeDiacritics(search).toLowerCase();
    setFilteredRatings(
      ratingsArray.filter((rating) => {
        const nameFilter = removeDiacritics(`${rating.userId.firstname} ${rating.userId.surname}`).toLowerCase().includes(searchedText);
        const foodFilter = removeDiacritics(rating.foodId.longName).toLowerCase().includes(searchedText);
        return nameFilter || foodFilter;
      }),
    );
  };

  const sortedRatings = filteredRatings.sort((r1, r2) => r1.foodId.longName.localeCompare(r2.foodId.longName));

  return (
    <div>
      <GridSection heading="ADMINISTRACE">
        <MyButton link="/admin" variant="outline-dark" icon={faHandPointLeft} text="ZPĚT" />
      </GridSection>
      <Section heading="SEZNAM HODNOCENÍ">
        {loading ? (
          <Loading />
        ) : (
          <>
            <RatingFilter handleFilter={handleFilter} />
            <Table responsive striped hover>
              <tbody>
                <tr>
                  <th>Jídlo</th>
                  <th>Uživatel</th>
                  <th>Hodnocení</th>
                </tr>
                {sortedRatings.map((rating) => (
                  <tr key={`rating_${rating.id}`} onClick={() => history.push(`/admin/ratings/preview/${rating.id}`)}>
                    <td>{rating.foodId.longName}</td>
                    <td>{`${rating.userId.firstname} ${rating.userId.surname}`}</td>
                    <td>
                      <FontAwesomeIcon icon={rating.stars < 0.5 ? faStar : rating.stars < 1 ? faStarHalf : faStarFilled} />
                      <FontAwesomeIcon icon={rating.stars < 1.5 ? faStar : rating.stars < 2 ? faStarHalf : faStarFilled} />
                      <FontAwesomeIcon icon={rating.stars < 2.5 ? faStar : rating.stars < 3 ? faStarHalf : faStarFilled} />
                      <FontAwesomeIcon icon={rating.stars < 3.5 ? faStar : rating.stars < 4 ? faStarHalf : faStarFilled} />
                      <FontAwesomeIcon icon={rating.stars < 4.5 ? faStar : rating.stars < 5 ? faStarHalf : faStarFilled} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        )}
      </Section>
    </div>
  );
};

export default RatingsList;
