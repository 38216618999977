import React from 'react';
import { useSelector } from 'react-redux';

import FoodTableContent from './FoodTableContent';

const FoodTablePermanent = () => {
  const foods = useSelector((state) => state.admin.foods.items);

  const foodsSorted = Object.values(foods)
    .filter((food) => food.permanent === true)
    .sort((a, b) => a.name.localeCompare(b.name));

  return <FoodTableContent foods={foodsSorted} />;
};

export default FoodTablePermanent;
