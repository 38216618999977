import React from 'react';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import { faListAlt } from '@fortawesome/free-solid-svg-icons/faListAlt';
import { useDispatch } from 'react-redux';

import GridSection from '../../../components/Section/GridSection';
import MyButton from '../../../components/MyButton/index';
import { userLogout } from '../../../redux/actions/userActions';

const Home = () => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(userLogout());
  };

  return (
    <div>
      <GridSection heading="ADMINISTRACE">
        <MyButton variant="danger" icon={faSignOutAlt} text="ODHLÁSIT SE" action={handleLogout} />
      </GridSection>
      <GridSection heading="VÝPISY Z DATABÁZE">
        <MyButton link="/admin/allergens" variant="outline-dark" icon={faListAlt} text="SEZNAM ALERGENŮ" />
        <MyButton link="/admin/foods" variant="outline-dark" icon={faListAlt} text="SEZNAM JÍDEL" />
        <MyButton link="/admin/ratings" variant="outline-dark" icon={faListAlt} text="SEZNAM HODNOCENÍ" />
        <MyButton link="/admin/menus" variant="outline-dark" icon={faListAlt} text="SEZNAM MENÍČEK" />
        <MyButton link="/admin/orders" variant="outline-dark" icon={faListAlt} text="SEZNAM OBJEDNÁVEK" />
        <MyButton link="/admin/users" variant="outline-dark" icon={faListAlt} text="SEZNAM UŽIVATELŮ" />
        <MyButton link="/admin/creditLog" variant="outline-dark" icon={faListAlt} text="VÝPIS ZMĚN KREDITU" />
      </GridSection>
    </div>
  );
};

export default Home;
