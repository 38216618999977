import React from 'react';
import { Image } from 'react-bootstrap';

import onsite from '../../assets/images/onsite_green.png';
import takeout from '../../assets/images/takeout_green.png';
import { __API_URL__ } from '../../constants';
import meat from '../../assets/images/meat.png';

const Food = (props) => {
  const { data, disabled, onsiteCount, takeoutCount, ordered, orderedCount, takeoutPickedCount, nontakeoutPickedCount, image, action } = props;
  const checked = onsiteCount > 0 || takeoutCount > 0;
  const className = `food${disabled ? ' disabled' : ''}`;
  return (
    <div className={`d-flex flex-column justify-content-center align-items-center ${className}`} onClick={action}>
      {!disabled && ordered && <span className="ordered" />}
      {!disabled && checked && <span className="checked" />}
      <div className="counts">
        {!disabled && orderedCount > 0 && <span className="count">{`${orderedCount}x`}</span>}
        {!disabled && onsiteCount > 0 && (
          <span className="count">
            {`${onsiteCount}x`} <img src={onsite} alt="" />
          </span>
        )}
        {!disabled && takeoutCount > 0 && (
          <span className="count">
            {`${takeoutCount}x`} <img src={takeout} alt="" />
          </span>
        )}
        {disabled && takeoutPickedCount && (
          <span className="stats">
            {takeoutPickedCount} <img src={takeout} alt="" />
          </span>
        )}
        {disabled && nontakeoutPickedCount && (
          <span className="stats">
            {nontakeoutPickedCount} <img src={onsite} alt="" />
          </span>
        )}
      </div>
      {image ? <Image fluid src={image} alt="food" /> : <Image fluid src={data.picture ? `${__API_URL__}/${data.picture}` : meat} alt="food" />}
      {data.price > 0 ? (
        <div className="d-flex flex-row w-100 justify-content-between align-items-center">
          <div className="text col-xs-8">{data.longName}</div>
          <div className="price col-xs-3">{`${data.price}Kč`}</div>
        </div>
      ) : (
        <div className="text">{data.longName}</div>
      )}
    </div>
  );
};

export default Food;
