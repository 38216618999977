export const processOrders = (data) => {
  const orders = {};
  let activeOrders = [];

  const ordersByDay = {};
  if (data && data.length) {
    data.forEach((order) => {
      orders[order.id] = processOrder(order);
      if (!ordersByDay[order.day]) {
        ordersByDay[order.day] = {};
      }
      ordersByDay[order.day][order.id] = order;
    });

    activeOrders = Object.entries(ordersByDay).map(([day, orders]) => {
      const uniqueFoods = {};
      Object.values(orders).forEach((order) => {
        order.foodInfo.forEach((food) => {
          if (!uniqueFoods[food.id]) {
            uniqueFoods[food.id] = food;
          }
        });
      });

      return {
        day,
        foods: Object.values(uniqueFoods).map((food) => {
          let takeoutCount = 0;
          let onsiteCount = 0;
          Object.values(orders).forEach((o) => {
            takeoutCount += o.foodInfo.filter((f) => f.id === food.id && f.takeout === true).length;
            onsiteCount += o.foodInfo.filter((f) => f.id === food.id && f.takeout === false).length;
          });
          return { ...food, takeoutCount, onsiteCount };
        }),
      };
    });
  }
  return { orders, activeOrders };
};

export const processDayOrders = (data) => {
  const orders = {};

  const activeDayOrders = data.map((order) => {
    orders[order.id] = processOrder(order);

    const uniqueFoods = {};
    order.foodInfo.forEach((food) => {
      if (!uniqueFoods[food.id]) {
        uniqueFoods[food.id] = food;
      }
    });

    return {
      ...order,
      foods: Object.values(uniqueFoods).map((food) => {
        const takeoutCount = order.foodInfo.filter((f) => f.id === food.id && f.takeout === true).length;
        const onsiteCount = order.foodInfo.filter((f) => f.id === food.id && f.takeout === false).length;
        return { ...food, takeoutCount, onsiteCount };
      }),
    };
  });

  return { orders, activeDayOrders };
};

export const processOrder = (data) => {
  const uniqueFoods = {};
  data.foodInfo.forEach((food) => {
    if (!uniqueFoods[food.id]) {
      uniqueFoods[food.id] = food;
    }
  });

  return {
    ...data,
    foods: Object.values(uniqueFoods).map((food) => ({
      ...food,
      onsiteCount: data.foodInfo.filter((f) => f.id === food.id && f.takeout === false).length,
      takeoutCount: data.foodInfo.filter((f) => f.id === food.id && f.takeout === true).length,
    })),
  };
};
