import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';

const MyButton = (props) => {
  const { variant, icon, text, text2, link, action } = props;

  const history = useHistory();

  return (
    <Button className="button" variant={variant} onClick={link ? () => history.push(link) : action}>
      <FontAwesomeIcon icon={icon} size="3x" />
      <span className="text">{text}</span>
      {text2 ? <span className="text">{text2}</span> : null}
    </Button>
  );
};

export default MyButton;
