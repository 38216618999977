import React, { useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { faHandPointLeft } from '@fortawesome/free-solid-svg-icons/faHandPointLeft';
import { faPrint } from '@fortawesome/free-solid-svg-icons/faPrint';
import { Table } from 'react-bootstrap';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons/faMinusCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';

import Section from '../../../components/Section/Section';
import GridSection from '../../../components/Section/GridSection';
import MyButton from '../../../components/MyButton/index';
import { getDayOrders } from '../../../redux/actions/ordersActions';
import { ENDPOINTS, KITCHEN_PASSWORD } from '../../../constants';

const OrderDayPreview = () => {
  const match = useRouteMatch();
  const history = useHistory();

  const loading = useSelector((state) => state.admin.orders.loadings.day);
  const orders = useSelector((state) => state.admin.orders.activeDayOrders);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading) {
      dispatch(getDayOrders(match.params.day));
    }
  }, []);

  const handlePrint = () => {
    window.open(`${ENDPOINTS.orders}/pdf?day=${match.params.day}&tkn=${KITCHEN_PASSWORD}`);
  };

  const sortOrders = (o1, o2) => {
    const fullname1 = `${o1.userId.firstname} ${o1.userId.surname}`;
    const fullname2 = `${o2.userId.firstname} ${o2.userId.surname}`;
    return fullname1.localeCompare(fullname2);
  };

  const pickedOrders = orders.filter((o) => o.breakfastPickedUp && o.lunchPickedUp && o.dinnerPickedUp && o.done).sort(sortOrders);
  const notPickedOrders = orders.filter((o) => !o.breakfastPickedUp || !o.lunchPickedUp || !o.dinnerPickedUp || !o.done).sort(sortOrders);

  const renderIcon = (order, food) => {
    let pickedUp = false;
    switch (food.type) {
      case 'permanent':
        pickedUp = true;
        break;
      case 'breakfast':
        pickedUp = order.done || order.breakfastPickedUp;
        break;
      case 'lunch':
        pickedUp = order.done || order.lunchPickedUp;
        break;
      case 'dinner':
        pickedUp = order.done || order.dinnerPickedUp;
        break;
    }

    return pickedUp ? (
      <FontAwesomeIcon icon={faCheckCircle} size="lg" style={{ color: '#6ac875' }} />
    ) : (
      <FontAwesomeIcon icon={faMinusCircle} size="lg" style={{ color: '#c5002f' }} />
    );
  };

  const renderOrders = (orders) => {
    return (
      <Table responsive hover className="spanned">
        <thead>
          <tr>
            <th>Uživatel</th>
            <th>Jídlo</th>
            <th>Konzumace na místě</th>
            <th>Jídlo s sebou</th>
            <th>Vyzvednuto</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) =>
            order.foods
              .sort((a, b) => {
                const sortTypes = ['permanent', 'breakfast', 'lunch', 'dinner'];
                const typeSorted = sortTypes.indexOf(a.type) - sortTypes.indexOf(b.type);
                return typeSorted ? typeSorted : a.longName.localeCompare(b.longName);
              })
              .map((food, index) => (
                <tr key={`order_key_${order.userId.id}_${food.id}`} onClick={() => history.push(`/admin/orders/preview/${order.id}`)}>
                  {index === 0 && <td className="first" rowSpan={order.foods.length}>{`${order.userId.firstname} ${order.userId.surname}`}</td>}
                  <td className={index === 0 ? 'first' : ''}>{food.longName}</td>
                  <td className={index === 0 ? 'first' : ''}>{`${food.onsiteCount}x`}</td>
                  <td className={index === 0 ? 'first' : ''}>{`${food.takeoutCount}x`}</td>
                  <td className={index === 0 ? 'first' : ''}>{renderIcon(order, food)}</td>
                </tr>
              )),
          )}
        </tbody>
      </Table>
    );
  };

  return (
    <div>
      <GridSection heading="ADMINISTRACE">
        <MyButton link="/admin/orders" variant="outline-dark" icon={faHandPointLeft} text="ZPĚT" />
        <MyButton action={handlePrint} variant="outline-dark" icon={faPrint} text="TISK" />
      </GridSection>
      <Section heading={`Přehled objednávek na ${moment(match.params.day).format('dddd D. M. YYYY').toUpperCase()}`}>
        {notPickedOrders.length || pickedOrders.length ? (
          <>
            {notPickedOrders.length ? (
              <>
                <h1>Nevyzvednuté</h1>
                {renderOrders(notPickedOrders)}
              </>
            ) : null}
            {pickedOrders.length ? (
              <>
                <h1>Vyzvednuté</h1>
                {renderOrders(pickedOrders)}
              </>
            ) : null}
          </>
        ) : (
          <p className="text-center">Na tento den nejsou k dispozici žádné objednávky.</p>
        )}
      </Section>
    </div>
  );
};

export default OrderDayPreview;
