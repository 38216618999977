import { Button, Col, Form, FormGroup } from 'react-bootstrap';
import React from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { createUser, updateUser } from '../../redux/actions/usersActions';

import { UserFormValidationSchema } from './validation';

const UserForm = (props) => {
  const { user, onSubmit } = props;

  const token = useSelector((state) => state.user.token);
  const loadingAdd = useSelector((state) => state.admin.users.loadings.create);
  const loadingEdit = useSelector((state) => state.admin.users.loadings.update);

  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    if (user) {
      dispatch(updateUser(token, user.id, values));
    } else {
      dispatch(createUser(token, values));
    }
    onSubmit();
  };

  const passwordGenerator = () => {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < 6; i += 1) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  };

  const initialValues = {
    email: user ? user.email : '',
    firstname: user ? user.firstname : '',
    surname: user ? user.surname : '',
    cardId: user ? user.cardId : '',
    password: passwordGenerator(),
    role: 'user',
  };

  return (
    <Formik initialValues={initialValues} enableReinitialize validationSchema={UserFormValidationSchema} onSubmit={handleSubmit}>
      {({ values, errors, touched, handleChange, handleSubmit }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Col xs={{ span: 6, offset: 3 }}>
            <Form.Group>
              <Form.Label>Jméno *</Form.Label>
              <Form.Control
                type="text"
                name="firstname"
                value={values.firstname}
                placeholder="Zadejte jméno"
                onChange={handleChange}
                isInvalid={touched.firstname && errors.firstname}
              />
              <Form.Control.Feedback type="invalid">{errors.firstname}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Příjmení *</Form.Label>
              <Form.Control
                type="text"
                name="surname"
                value={values.surname}
                placeholder="Zadejte příjmení"
                onChange={handleChange}
                isInvalid={touched.surname && errors.surname}
              />
              <Form.Control.Feedback type="invalid">{errors.surname}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>E-mail *</Form.Label>
              <Form.Control
                type="e-mail"
                name="email"
                value={values.email}
                placeholder="Zadejte e-mail"
                onChange={handleChange}
                isInvalid={touched.email && errors.email}
              />
              <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
            </Form.Group>
            <FormGroup>
              <Form.Label>ID karty *</Form.Label>
              <Form.Control
                type="text"
                name="cardId"
                value={values.cardId}
                placeholder="Zadejte ID karty"
                onChange={handleChange}
                isInvalid={touched.cardId && errors.cardId}
              />
              <Form.Control.Feedback type="invalid">{errors.cardId}</Form.Control.Feedback>
            </FormGroup>
            <FormGroup>
              <Button type="submit" variant="outline-dark" disabled={loadingAdd || loadingEdit}>
                {user ? 'Upravit uživatele' : 'Přidat uživatele'}
              </Button>
            </FormGroup>
          </Col>
        </Form>
      )}
    </Formik>
  );
};

export default UserForm;
