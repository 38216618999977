import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AllergensList from './AllergensList';
import AllergenForms from './AllergenForms';
import AllergenPreview from './AllergenPreview';

const Allergens = () => {
  return (
    <Switch>
      <Route path="/admin/allergens/add" component={AllergenForms} />
      <Route path="/admin/allergens/edit/:id" component={AllergenForms} />
      <Route path="/admin/allergens/preview/:id" component={AllergenPreview} />
      <Route path="/admin/allergens" component={AllergensList} />
    </Switch>
  );
};

export default Allergens;
