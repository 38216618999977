import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { Col, Table } from 'react-bootstrap';
import { notify } from 'reapop';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons/faMinusCircle';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faHandPointLeft } from '@fortawesome/free-solid-svg-icons/faHandPointLeft';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons/faDollarSign';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';

import GridSection from '../../../components/Section/GridSection';
import MyButton from '../../../components/MyButton/index';
import Section from '../../../components/Section/Section';
import Loading from '../../../components/Loading/index';
import WeekChanger from '../../../components/WeekChanger/index';
import CreditModal from '../../../components/CreditModal';
import { changeUserActive, getUser } from '../../../redux/actions/usersActions';
import { ENDPOINTS, KITCHEN_PASSWORD } from '../../../constants';

const UserPreview = () => {
  const match = useRouteMatch();

  const token = useSelector((state) => state.user.token);
  const loading = useSelector((state) => state.admin.users.loadings.one);
  const user = useSelector((state) => state.admin.users.items[match.params.id]);

  const dispatch = useDispatch();

  const [from, setFrom] = useState(moment().startOf('week'));
  const [to, setTo] = useState(moment().endOf('week'));
  const [loadingOrders, setLoadingOrders] = useState(false);
  const [orders, setOrders] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (match.params.id && !loading) {
      dispatch(getUser(token, match.params.id));
    }
  }, []);

  useEffect(() => {
    if (user && !loadingOrders) {
      getOrders();
    }
  }, [user]);

  const handleChangeActive = (user) => {
    dispatch(changeUserActive(token, user.id, user.active));
  };

  const getOrders = async () => {
    setLoadingOrders(true);
    const config = {
      params: {
        tkn: KITCHEN_PASSWORD,
        userId: user.id,
        from: from.toISOString(),
        to: to.toISOString(),
      },
    };

    try {
      const response = await axios.get(ENDPOINTS.orders, config);
      const { data } = response;

      const orders = data.map((order) => {
        const foods = [];
        const uniqueFoods = {};
        order.foodInfo.forEach((food) => {
          if (!uniqueFoods[food.id]) {
            uniqueFoods[food.id] = food;
          }
        });

        Object.values(uniqueFoods).forEach((food) => {
          const takeoutCount = order.foodInfo.filter((f) => f.id === food.id && f.takeout === true).length;
          const onsiteCount = order.foodInfo.filter((f) => f.id === food.id && f.takeout === false).length;
          foods.push({ ...food, takeoutCount, onsiteCount });
        });

        return {
          ...order,
          foods,
        };
      });
      setOrders(orders);
      setLoadingOrders(false);
    } catch (e) {
      dispatch(notify({}));
    }
  };

  const handlePreviousWeek = () => {
    setFrom(from.subtract(1, 'week'));
    setTo(to.subtract(1, 'week'));
    getOrders();
  };

  const handleNextWeek = () => {
    setFrom(from.add(1, 'week'));
    setTo(to.add(1, 'week'));
    getOrders();
  };

  if (!user) return null;

  return (
    <div>
      <GridSection heading="ADMINISTRACE">
        <MyButton link="/admin/users" variant="outline-dark" icon={faHandPointLeft} text="ZPĚT" />
        <MyButton variant="outline-dark" icon={faDollarSign} text="ZMĚNA ZŮSTATKU" action={() => setShowModal(true)} />
        <MyButton link={`/admin/users/edit/${user.id}`} variant="outline-dark" icon={faEdit} text="UPRAVIT UŽIVATELE" />
        <MyButton
          variant={user.active ? 'danger' : 'success'}
          icon={user.active ? faMinusCircle : faCheckCircle}
          text={user.active ? 'DEAKTIVOVAT UŽIVATELE' : 'AKTIVOVAT UŽIVATELE'}
          action={() => handleChangeActive(user)}
        />
      </GridSection>
      <Section heading="Náhled uživatele">
        {loading ? (
          <Loading />
        ) : (
          <Col xs={{ span: 8, offset: 2 }}>
            <Table responsive striped>
              <tbody>
                <tr>
                  <th>ID karty:</th>
                  <td>{user.cardId}</td>
                </tr>
                <tr>
                  <th>Jméno a příjmení:</th>
                  <td>{`${user.firstname} ${user.surname}`}</td>
                </tr>
                <tr>
                  <th>E-mail:</th>
                  <td>{user.email}</td>
                </tr>
                <tr>
                  <th>Stav účtu:</th>
                  <td style={{ color: user.credit.balance - user.credit.bill < 0 ? 'red' : '' }}>{(user.credit.balance - user.credit.bill).toFixed(2)} Kč</td>
                </tr>
                <tr>
                  <th>Aktivní:</th>
                  <td>{user.active ? 'ano' : 'ne'}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        )}
        <Col sm={12}>
          <WeekChanger
            text={`${moment(from).week()}. týden: ${moment(from).format('LL')} - ${moment(to).format('LL')}`}
            previousWeek={handlePreviousWeek}
            nextWeek={handleNextWeek}
          />
          {loadingOrders ? (
            <Loading />
          ) : (
            <Table responsive hover striped>
              <tbody>
                <tr>
                  <th>Datum</th>
                  <th>Den</th>
                  <th>Jídlo</th>
                  <th>Konzumace na místě</th>
                  <th>Jídlo s sebou</th>
                  <th>Vyzvednuto</th>
                </tr>
                {orders && orders.length
                  ? orders.map((order) =>
                      order && order.foods.length
                        ? order.foods.map((food, index) => (
                            <tr key={`order_key_${order.day}_${food._id}`} onClick={() => history.push(`/admin/orders/preview/${order.id}`)}>
                              {index === 0 && <td rowSpan={order.foods.length}>{moment(order.day).format('D.M.')}</td>}
                              {index === 0 && <td rowSpan={order.foods.length}>{moment(order.day).format('dddd').toUpperCase()}</td>}
                              <td>{food.name}</td>
                              <td>{`${food.onsiteCount}x`}</td>
                              <td>{`${food.takeoutCount}x`}</td>
                              <td>{order.done ? 'ano' : 'ne'}</td>
                            </tr>
                          ))
                        : null,
                    )
                  : null}
              </tbody>
            </Table>
          )}
        </Col>
      </Section>
      <CreditModal show={showModal} user={user} onHide={() => setShowModal(false)} />
    </div>
  );
};

export default UserPreview;
