import { USER_LOADING, USER_FAILURE, USER_LOGIN, USER_LOGOUT } from '../types/userTypes';

const INITIAL_STATE = {
  loadings: {
    userLogin: true,
  },
  errors: {},
};

const userReducer = (currentState = INITIAL_STATE, action) => {
  switch (action.type) {
    case USER_LOADING:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: true,
        },
      };
    case USER_FAILURE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: false,
        },
        errors: {
          ...currentState.errors,
          [action.name]: action.error,
        },
      };
    case USER_LOGIN:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          userLogin: false,
        },
        errors: {
          ...currentState.errors,
          userLogin: undefined,
        },
        token: action.token,
        user: action.user,
      };
    case USER_LOGOUT:
      return {
        ...currentState,
        token: undefined,
        user: undefined,
      };
    default:
      return currentState;
  }
};

export default userReducer;
