export const processPermanentMenu = (data) => {
  const permanentMenu = data.filter((item) => item.name !== 'Polévka').sort((a, b) => a.order - b.order);
  const soup = data.find((item) => item.name === 'Polévka');
  return { permanentMenu, soup };
};

export const processMenu = (menu, orders, userId) => {
  const foods = {};
  let userOrder;
  if (orders) {
    menu.foodIds.forEach((food) => {
      let takeoutTotalCount = 0;
      let nonTakeoutTotalCount = 0;
      let takeoutPickedCount = 0;
      let nonTakeoutPickedCount = 0;
      userOrder = orders.find((o) => o.userId.id === userId);
      orders.forEach((order) => {
        const orderedFoods = order.foodInfo.filter((f) => f.id === food.id);
        const takeoutFoods = orderedFoods.filter((f) => f.takeout === true);
        const nonTakeoutFoods = orderedFoods.filter((f) => f.takeout === false);
        takeoutTotalCount += takeoutFoods.length;
        nonTakeoutTotalCount += nonTakeoutFoods.length;

        const pickedFilter = (order, f) => {
          return (
            order.done ||
            (f.type === 'breakfast' && order.breakfastPickedUp) ||
            (f.type === 'lunch' && order.lunchPickedUp) ||
            (f.type === 'dinner' && order.dinnerPickedUp)
          );
        };
        takeoutPickedCount += takeoutFoods.filter((f) => pickedFilter(order, f)).length;
        nonTakeoutPickedCount += nonTakeoutFoods.filter((f) => pickedFilter(order, f)).length;

        foods[food.id] = {
          ...food,
          takeoutPickedCount,
          takeoutTotalCount,
          nonTakeoutPickedCount,
          nonTakeoutTotalCount,
          pickedCount: takeoutPickedCount + nonTakeoutPickedCount,
          totalCount: orderedFoods.length,
        };
      });
    });
  } else {
    menu.foodIds.forEach((food) => {
      foods[food.id] = {
        ...food,
        takeoutPickedCount: 0,
        takeoutTotalCount: 0,
        nonTakeoutPickedCount: 0,
        nonTakeoutTotalCount: 0,
        pickedCount: 0,
        totalCount: 0,
      };
    });
  }
  return {
    ...menu,
    foods,
    order: userOrder,
  };
};
