import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import UserLoginForm from '../../forms/UserForms/UserLoginForm';

const Login = () => {
  const history = useHistory();

  const loading = useSelector((state) => state.user.loadings.userLogin);
  const error = useSelector((state) => state.user.errors.userLogin);

  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (isSubmitted && !loading && !error) {
      setIsSubmitted(false);
      history.replace('/orders');
    }
  }, [loading, error]);

  return (
    <div id="login-background">
      <div id="login">
        <h1>DNES {moment().format('dddd D.M').toUpperCase()}</h1>
        <UserLoginForm onSubmit={() => setIsSubmitted(true)} />
      </div>
    </div>
  );
};

export default Login;
