import {
  USERS_ALL,
  USERS_CHANGE_ACTIVE,
  USERS_CHANGE_CREDIT,
  USERS_CREATE,
  USERS_FAILURE,
  USERS_LOADING,
  USERS_ONE,
  USERS_REMOVE,
  USERS_UPDATE,
} from '../../types/usersTypes';

const INITIAL_STATE = {
  loadings: {},
  errors: {},
  items: {},
};

const usersReducer = (currentState = INITIAL_STATE, action) => {
  let newState;
  switch (action.type) {
    case USERS_LOADING:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: true,
        },
      };
    case USERS_FAILURE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: false,
        },
        errors: {
          ...currentState.errors,
          [action.name]: action.error,
        },
      };
    case USERS_ALL:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          all: false,
        },
        errors: {
          ...currentState.errors,
          all: undefined,
        },
        items: action.users,
      };
    case USERS_ONE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          one: false,
        },
        errors: {
          ...currentState.errors,
          one: undefined,
        },
        items: {
          ...currentState.items,
          [action.user.id]: action.user,
        },
      };
    case USERS_CREATE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          create: false,
        },
        errors: {
          ...currentState.errors,
          create: undefined,
        },
        items: {
          ...currentState.items,
          [action.user.id]: action.user,
        },
      };
    case USERS_UPDATE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          update: false,
        },
        errors: {
          ...currentState.errors,
          update: undefined,
        },
        items: {
          ...currentState.items,
          [action.user.id]: {
            ...currentState.items[action.user.id],
            ...action.user,
          },
        },
      };
    case USERS_REMOVE:
      newState = {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          remove: false,
        },
        errors: {
          ...currentState.errors,
          remove: undefined,
        },
        items: {
          ...currentState.items,
        },
      };
      delete newState.items[action.user.id];
      return newState;
    case USERS_CHANGE_ACTIVE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          changeActive: false,
        },
        errors: {
          ...currentState.errors,
          changeActive: undefined,
        },
        items: {
          ...currentState.items,
          [action.userId]: {
            ...currentState.items[action.userId],
            active: action.active,
          },
        },
      };
    case USERS_CHANGE_CREDIT:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          changeCredit: false,
        },
        errors: {
          ...currentState.errors,
          changeCredit: undefined,
        },
        items: {
          ...currentState.items,
          [action.userId]: {
            ...currentState.items[action.userId],
            credit: {
              ...currentState.items[action.userId].credit,
              balance: action.balance,
            },
          },
        },
      };
    default:
      return currentState;
  }
};

export default usersReducer;
