import React from 'react';
import { Route, Switch } from 'react-router-dom';

import OrdersList from './OrdersList';
import OrderPreview from './OrderPreview';
import OrderDayPreview from './OrderDayPreview';
import OrderChangePickupForm from './OrderChangePickupForm';

const Orders = () => {
  return (
    <Switch>
      <Route path="/admin/orders/dayPreview/:day" component={OrderDayPreview} />
      <Route path="/admin/orders/preview/:id" component={OrderPreview} />
      <Route path="/admin/orders/change/:id" component={OrderChangePickupForm} />
      <Route path="/admin/orders" component={OrdersList} />
    </Switch>
  );
};

export default Orders;
