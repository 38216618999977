import React, { useCallback } from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { updateOrderPickup } from '../../redux/actions/ordersActions';

const OrderPickupForm = (props) => {
  const { order, onSubmit } = props;

  const token = useSelector((state) => state.user.token);

  const loadingEdit = useSelector((state) => state.admin.orders.loadings.update);

  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    (values) => {
      dispatch(updateOrderPickup(token, order.id, values));
      onSubmit();
    },
    [token, order, onSubmit],
  );

  const initialValues = {
    breakfastPickedUp: order ? order.breakfastPickedUp : false,
    lunchPickedUp: order ? order.lunchPickedUp : false,
    dinnerPickedUp: order ? order.dinnerPickedUp : false,
  };

  return (
    <Formik initialValues={initialValues} enableReinitialize onSubmit={handleSubmit}>
      {({ values, setFieldValue, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Col xs={{ span: 2, offset: 5 }}>
            <Form.Group className="mb-4">
              <Form.Check type="checkbox" className="d-flex flex-row align-items-center">
                <Form.Check.Input
                  type="checkbox"
                  name="breakfastPickedUp"
                  style={{ marginTop: '0.125rem' }}
                  value={values.breakfastPickedUp}
                  checked={values.breakfastPickedUp}
                  onChange={() => setFieldValue('breakfastPickedUp', !values.breakfastPickedUp)}
                />
                <Form.Check.Label onClick={() => setFieldValue('breakfastPickedUp', !values.breakfastPickedUp)}>Vyzvednuté snídaně</Form.Check.Label>
              </Form.Check>
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Check type="checkbox" className="d-flex flex-row align-items-center">
                <Form.Check.Input
                  type="checkbox"
                  name="lunchPickedUp"
                  style={{ marginTop: '0.125rem' }}
                  value={values.lunchPickedUp}
                  checked={values.lunchPickedUp}
                  onChange={() => setFieldValue('lunchPickedUp', !values.lunchPickedUp)}
                />
                <Form.Check.Label onClick={() => setFieldValue('lunchPickedUp', !values.lunchPickedUp)}>Vyzvednuté obědy</Form.Check.Label>
              </Form.Check>
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Check type="checkbox" className="d-flex flex-row align-items-center">
                <Form.Check.Input
                  type="checkbox"
                  name="dinnerPickedUp"
                  style={{ marginTop: '0.125rem' }}
                  value={values.dinnerPickedUp}
                  checked={values.dinnerPickedUp}
                  onChange={() => setFieldValue('dinnerPickedUp', !values.dinnerPickedUp)}
                />
                <Form.Check.Label onClick={() => setFieldValue('dinnerPickedUp', !values.dinnerPickedUp)}>Vyzvednuté večeře</Form.Check.Label>
              </Form.Check>
            </Form.Group>
            <Form.Group>
              <Button variant="outline-dark" type="submit" disabled={loadingEdit}>
                Upravit vyzvednutí
              </Button>
            </Form.Group>
          </Col>
        </Form>
      )}
    </Formik>
  );
};

export default OrderPickupForm;
