import React from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { createAllergen, updateAllergen } from '../../redux/actions/allergensActions';

import AllergenValidationSchema from './validation';

const AllergenForm = (props) => {
  const { allergen, buttonText, onSubmit } = props;

  const token = useSelector((state) => state.user.token);
  const loadingAdd = useSelector((state) => state.admin.allergens.loadings.allergensCreate);
  const loadingEdit = useSelector((state) => state.admin.allergens.loadings.allergensUpdate);

  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    if (allergen) {
      dispatch(updateAllergen(token, allergen.id, values));
    } else {
      dispatch(createAllergen(token, values));
    }
    onSubmit();
  };

  return (
    <Formik initialValues={{ name: allergen ? allergen.name : '' }} validationSchema={AllergenValidationSchema} onSubmit={handleSubmit}>
      {({ values, errors, touched, handleChange, handleSubmit }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Col xs={{ span: 6, offset: 3 }}>
            <Form.Group controlId="name">
              <Form.Label>Název *</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={values.name}
                placeholder="Zadejte název"
                onChange={handleChange}
                isInvalid={touched.name && errors.name}
              />
              <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Button variant="outline-dark" disabled={loadingAdd || loadingEdit} type="submit">
                {buttonText}
              </Button>
            </Form.Group>
          </Col>
        </Form>
      )}
    </Formik>
  );
};

export default AllergenForm;
