import axios from 'axios';
import { notify } from 'reapop';

import { ENDPOINTS } from '../../constants';
import {
  USERS_ALL,
  USERS_CHANGE_ACTIVE,
  USERS_CHANGE_CREDIT,
  USERS_CREATE,
  USERS_FAILURE,
  USERS_LOADING,
  USERS_ONE,
  USERS_REMOVE,
  USERS_UPDATE,
} from '../types/usersTypes';
import { processUsers } from '../helpers/usersHelpers';

const AUsersLoading = (name) => ({
  type: USERS_LOADING,
  name,
});

const AUsersFailure = (name, error) => ({
  type: USERS_FAILURE,
  name,
  error,
});

const AGetUsers = (users) => ({
  type: USERS_ALL,
  users,
});

const AGetUser = (user) => ({
  type: USERS_ONE,
  user,
});

const ACreateUser = (user) => ({
  type: USERS_CREATE,
  user,
});

const AUpdateUser = (user) => ({
  type: USERS_UPDATE,
  user,
});

const ARemoveUser = (user) => ({
  type: USERS_REMOVE,
  user,
});

const AUserChangeActivity = (userId, active) => ({
  type: USERS_CHANGE_ACTIVE,
  userId,
  active,
});

const AUserChangeCredit = (userId, balance) => ({
  type: USERS_CHANGE_CREDIT,
  userId,
  balance,
});

export const getUsers = (token) => async (dispatch) => {
  const actionName = 'all';

  dispatch(AUsersLoading(actionName));

  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.get(ENDPOINTS.users, config);
    const { data } = response;
    const users = processUsers(data);
    dispatch(AGetUsers(users));
  } catch (e) {
    dispatch(AUsersFailure(actionName, e));
    dispatch(
      notify({
        title: 'Získání seznamu uživatelů',
        message: 'Nepodařilo se získat seznam uživatelů',
        status: 'error',
        dismissAfter: 3000,
      }),
    );
  }
};

export const getUser = (token, userId) => async (dispatch) => {
  const actionName = 'one';

  dispatch(AUsersLoading(actionName));

  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.get(`${ENDPOINTS.users}/${userId}`, config);
    const { data } = response;
    dispatch(AGetUser(data));
  } catch (e) {
    dispatch(AUsersFailure(actionName, e));
    dispatch(
      notify({
        title: 'Získání uživatele',
        message: 'Nepodařilo se získat informace o uživateli',
        status: 'error',
        dismissAfter: 3000,
      }),
    );
  }
};

export const createUser = (token, values) => async (dispatch) => {
  const actionName = 'create';

  dispatch(AUsersLoading(actionName));

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.post(ENDPOINTS.users, values, config);
    const { data } = response;
    dispatch(ACreateUser(data));
    dispatch(
      notify({
        title: 'Přídání uživatele',
        message: `Uživatel ${data.firstname} ${data.surname} byl přidán.`,
        status: 'success',
        dismissAfter: 3000,
      }),
    );
  } catch (e) {
    dispatch(AUsersFailure(actionName, e));
    dispatch(
      notify({
        title: 'Přídání uživatele',
        message: `Uživatele se nepodařilo přidat.`,
        status: 'error',
        dismissAfter: 3000,
      }),
    );
  }
};

export const updateUser = (token, userId, values) => async (dispatch) => {
  const actionName = 'update';

  dispatch(AUsersLoading(actionName));

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.put(`${ENDPOINTS.users}/${userId}`, values, config);
    const { data } = response;
    dispatch(AUpdateUser(data));
    dispatch(
      notify({
        title: 'Úprava uživatele',
        message: `Uživatel ${data.firstname} ${data.surname} byl upraven.`,
        status: 'success',
        dismissAfter: 3000,
      }),
    );
  } catch (e) {
    dispatch(AUsersFailure(actionName, e));
    dispatch(
      notify({
        title: 'Úprava uživatele',
        message: 'Uživatele se nepodařilo upravit.',
        status: 'error',
        dismissAfter: 3000,
      }),
    );
  }
};

export const removeUser = (token, user) => async (dispatch) => {
  const actionName = 'remove';

  dispatch(AUsersLoading(actionName));

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    await axios.delete(`${ENDPOINTS.users}/${user.id}`, config);
    dispatch(ARemoveUser(user));
    dispatch(
      notify({
        title: 'Odstranění uživatele',
        message: `Uživatel ${user.firstname} ${user.surname} byl odstraněn.`,
        status: 'success',
        dismissAfter: 3000,
      }),
    );
  } catch (e) {
    dispatch(AUsersFailure(actionName, e));
    dispatch(
      notify({
        title: 'Odstranění uživatele',
        message: `Uživatele se nepodařilo odstranit.`,
        status: 'error',
        dismissAfter: 3000,
      }),
    );
  }
};

export const changeUserActive = (token, userId, active) => async (dispatch) => {
  const actionName = 'changeActive';
  dispatch(AUsersLoading(actionName));
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.put(`${ENDPOINTS.users}/${userId}`, { active: !active }, config);
    const { data } = response;
    const { active: newActive } = data;
    dispatch(AUserChangeActivity(userId, newActive));
  } catch (e) {
    dispatch(AUsersFailure(actionName, e));
    dispatch(
      notify({
        title: 'Změna aktivity uživatele',
        message: 'Změna aktivity uživatele se nepodařila',
        status: 'error',
        dismissAfter: 3000,
      }),
    );
  }
};

export const changeUserCredit = (token, userId, values) => async (dispatch) => {
  const actionName = 'changeCredit';

  dispatch(AUsersLoading(actionName));

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.put(`${ENDPOINTS.credit}/${userId}`, values, config);
    const { data } = response;
    const { balance } = data;
    dispatch(AUserChangeCredit(userId, balance));
    dispatch(
      notify({
        title: 'Změna zůstatku',
        message: 'Změna zůstatku provedena',
        status: 'success',
        dismissAfter: 3000,
      }),
    );
  } catch (e) {
    dispatch(AUsersFailure(actionName, e));
    dispatch(
      notify({
        title: 'Změna zůstatku',
        message: 'Změna zůstatku se nezdařila',
        status: 'error',
        dismissAfter: 3000,
      }),
    );
  }
};
