import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { faHandPointLeft } from '@fortawesome/free-solid-svg-icons/faHandPointLeft';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faBan } from '@fortawesome/free-solid-svg-icons/faBan';
import { Col, Table } from 'react-bootstrap';
import { faHandHolding } from '@fortawesome/free-solid-svg-icons';

import GridSection from '../../../components/Section/GridSection';
import MyButton from '../../../components/MyButton';
import Section from '../../../components/Section/Section';
import Loading from '../../../components/Loading';
import { getOrder, removeOrder, updateDoneOrder } from '../../../redux/actions/ordersActions';

const OrderPreview = () => {
  const match = useRouteMatch();
  const history = useHistory();

  const token = useSelector((state) => state.user.token);
  const loading = useSelector((state) => state.admin.orders.loadings.one);
  const order = useSelector((state) => state.admin.orders.items[match.params.id]);
  const loadingRemove = useSelector((state) => state.admin.orders.loadings.remove);
  const errorRemove = useSelector((state) => state.admin.orders.errors.remove);

  const dispatch = useDispatch();

  const [isRemoved, setIsRemoved] = useState(false);

  useEffect(() => {
    if (!loading) {
      dispatch(getOrder(token, match.params.id));
    }
  }, []);

  useEffect(() => {
    if (isRemoved && !loadingRemove && !errorRemove) {
      setIsRemoved(false);
      history.goBack();
    }
  }, [loadingRemove, errorRemove]);

  const handleRemove = () => {
    setIsRemoved(true);
    dispatch(removeOrder(token, order));
  };

  const handleChangeDone = (order) => {
    dispatch(updateDoneOrder(token, order.id, order.done));
  };

  if (!order) return null;

  return (
    <div>
      <GridSection heading="ADMINISTRACE">
        <MyButton link={`/admin/orders/dayPreview/${order.day}`} variant="outline-dark" icon={faHandPointLeft} text="ZPĚT" />
        <MyButton link={`/admin/orders/change/${order.id}`} variant="outline-dark" icon={faHandHolding} text="UPRAVIT VYZVEDNUTÍ" />
        <MyButton variant="warning" icon={faBan} text="ZRUŠIT VYZVEDNUTÍ" action={() => handleChangeDone(order)} />
        <MyButton variant="danger" icon={faTrash} text="ODSTRANIT OBJEDNÁVKU" action={handleRemove} />
      </GridSection>
      <Section heading="NÁHLED OBJEDNÁVKY">
        {loading ? (
          <Loading />
        ) : (
          <Col xs={{ span: 8, offset: 2 }}>
            <Table responsive striped>
              <tbody>
                <tr>
                  <th>Datum:</th>
                  <td>{moment(order.day).format('dddd D.M.')}</td>
                </tr>
                <tr>
                  <th>Uživatel:</th>
                  <td>{`${order.userId.firstname} ${order.userId.surname}`}</td>
                </tr>
                <tr>
                  <th>Potvrzená:</th>
                  <td>{order.confirmed ? 'ano' : 'ne'}</td>
                </tr>
                <tr>
                  <th>Vyzvednutá:</th>
                  <td>{order.done ? 'ano' : 'ne'}</td>
                </tr>
                <tr>
                  <th>Vyzvednutá snídaně:</th>
                  <td>{order.breakfastPickedUp ? 'ano' : 'ne'}</td>
                </tr>
                <tr>
                  <th>Vyzvednutý oběd:</th>
                  <td>{order.lunchPickedUp ? 'ano' : 'ne'}</td>
                </tr>
                <tr>
                  <th>Vyzvednutá večeře:</th>
                  <td>{order.dinnerPickedUp ? 'ano' : 'ne'}</td>
                </tr>
              </tbody>
            </Table>
            <Table responsive striped>
              <tbody>
                <tr>
                  <th colSpan={3}>Jídla</th>
                </tr>
                <tr>
                  <th>Název</th>
                  <th>Konzumace na místě</th>
                  <th>Jídlo s sebou</th>
                </tr>
                {order.foods
                  .sort((a, b) => {
                    const sortTypes = ['permanent', 'breakfast', 'lunch', 'dinner'];
                    const typeSorted = sortTypes.indexOf(a.type) - sortTypes.indexOf(b.type);
                    return typeSorted ? typeSorted : a.longName.localeCompare(b.longName);
                  })
                  .map((food) => (
                    <tr key={`food_${food.id}`}>
                      <td>{food.longName}</td>
                      <td>{`${food.onsiteCount}x`}</td>
                      <td>{`${food.takeoutCount}x`}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        )}
      </Section>
    </div>
  );
};

export default OrderPreview;
