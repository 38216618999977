import { Form } from 'react-bootstrap';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { userLogin } from '../../redux/actions/userActions';
import chipImage from '../../assets/images/chip.png';

const Chip = () => {
  const user = useSelector((state) => state.user.user);

  const dispatch = useDispatch();

  const refInput = useRef();

  const [showInput, setShowInput] = useState(false);
  const [cardId, setCardId] = useState('');

  useEffect(() => {
    setInterval(() => {
      refInput.current.focus();
    }, 1000);
  }, []);

  useEffect(() => {
    if (!user) {
      refInput.current.focus();
    }
  }, [user]);

  const handleChangeID = (e) => {
    const value = e ? e.target.value : cardId;

    const newCardId = value.replace(/[\[,\]]/gi, ''); // odstraňuje závorky ([]), které jsou přidány po načtení čtečkou
    setCardId(newCardId);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setCardId('');
    if (!user) {
      dispatch(userLogin({ cardId, password: cardId }));
    }
  };

  return (
    <div className="chip" onClick={() => setShowInput(true)}>
      <span className="text">Pro objednání přilož chip</span>
      <img src={chipImage} alt="chip" />
      <Form onSubmit={handleSubmit}>
        <Form.Control className={showInput ? '' : 'hiddenInput'} type="text" ref={refInput} value={cardId} onChange={handleChangeID} />
      </Form>
      <span className="loggedUser">{`Přihlášen: ${user ? `${user.firstname} ${user.surname}` : 'nikdo'}`}</span>
    </div>
  );
};

export default Chip;
