import { RATINGS_ALL, RATINGS_FAILURE, RATINGS_LOADING, RATINGS_ONE } from '../../types/ratingsTypes';

const INITIAL_STATE = {
  loadings: {},
  errors: {},
  items: {},
};

const ratingsReducer = (currentState = INITIAL_STATE, action) => {
  switch (action.type) {
    case RATINGS_LOADING:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: true,
        },
      };
    case RATINGS_FAILURE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: false,
        },
        errors: {
          ...currentState.errors,
          [action.name]: action.error,
        },
      };
    case RATINGS_ALL:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          all: false,
        },
        errors: {
          ...currentState.errors,
          all: undefined,
        },
        items: action.ratings,
      };
    case RATINGS_ONE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          one: false,
        },
        errors: {
          ...currentState.errors,
          one: undefined,
        },
        items: {
          ...currentState.items,
          [action.rating.id]: action.rating,
        },
      };
    default:
      return currentState;
  }
};

export default ratingsReducer;
