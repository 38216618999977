import React from 'react';
import { useSelector } from 'react-redux';

import FoodTableContent from './FoodTableContent';

const FoodTableByType = (props) => {
  const { type } = props;

  const foods = useSelector((state) => state.admin.foods.items);

  const foodsSorted = Object.values(foods)
    .filter((food) => food.type === type)
    .sort((a, b) => a.name.localeCompare(b.name));

  return <FoodTableContent foods={foodsSorted} />;
};

export default FoodTableByType;
