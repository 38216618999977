import { Button, Form, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import React, { useEffect, useState } from 'react';

let timeoutId;

const FoodFilter = (props) => {
  const { handleFilter } = props;

  const [search, setSearch] = useState('');

  useEffect(() => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => handleFilter(search), 1000);
  }, [search]);

  return (
    <Form
      className="mt-3 mb-3"
      onSubmit={(e) => {
        e.preventDefault();
        handleFilter(search);
      }}>
      <Form.Group>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>
              <FontAwesomeIcon icon={faSearch} />
            </InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            type="text"
            placeholder="Hledejte podle názvu jídla..."
            aria-describedby="inputGroupPrepend"
            autoComplete="off"
            name="search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <InputGroup.Append>
            <Button type="submit">Hledat</Button>
          </InputGroup.Append>
        </InputGroup>
      </Form.Group>
    </Form>
  );
};

export default FoodFilter;
