import React, { useEffect, useState } from 'react';
import moment from 'moment';
import 'moment/locale/cs';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'react-bootstrap';
import { faHandPointLeft } from '@fortawesome/free-solid-svg-icons/faHandPointLeft';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';

import GridSection from '../../../components/Section/GridSection';
import MyButton from '../../../components/MyButton/index';
import Section from '../../../components/Section/Section';
import Loading from '../../../components/Loading/index';
import WeekChanger from '../../../components/WeekChanger';
import Food from '../../../components/Food/index';
import { getMenus } from '../../../redux/actions/menusActions';

const MenusList = () => {
  const history = useHistory();

  const loading = useSelector((state) => state.admin.menus.loadings.all);
  const menus = useSelector((state) => Object.values(state.admin.menus.items));

  const dispatch = useDispatch();

  const [from, setFrom] = useState(moment().startOf('week'));
  const [to, setTo] = useState(moment().endOf('week'));

  useEffect(() => {
    if (!loading) {
      dispatch(getMenus(from, to));
    }
  }, []);

  const handlePreviousWeek = () => {
    setFrom(from.subtract(1, 'week'));
    setTo(to.subtract(1, 'week'));
    dispatch(getMenus(from, to));
  };

  const handleNextWeek = () => {
    setFrom(from.add(1, 'week'));
    setTo(to.add(1, 'week'));
    dispatch(getMenus(from, to));
  };
  return (
    <div>
      <GridSection heading="ADMINISTRACE">
        <MyButton link="/admin" variant="outline-dark" icon={faHandPointLeft} text="ZPĚT" />
        <MyButton link="/admin/menus/add" variant="success" icon={faPlusCircle} text="PŘIDAT MENU" />
      </GridSection>
      <Section heading="SEZNAM MENÍČEK">
        <WeekChanger
          text={`${moment(from).week()}. týden: ${moment(from).format('LL')} - ${moment(to).format('LL')}`}
          previousWeek={handlePreviousWeek}
          nextWeek={handleNextWeek}
        />
        {loading ? (
          <Loading />
        ) : (
          <Table responsive className="spanned cursor-pointer">
            <thead>
              <tr>
                <th>Datum</th>
                <th>Den</th>
                <th>Typ</th>
                <th>Seznam jídel</th>
              </tr>
            </thead>
            <tbody>
              {menus.map((menu) => (
                <React.Fragment key={`menu_${menu.day}`}>
                  <tr onClick={() => history.push(`/admin/menus/preview/${menu.id}`)}>
                    <td className="first" rowSpan={3}>
                      {moment(menu.day).format('D.M.')}
                    </td>
                    <td className="first" rowSpan={3}>
                      {moment(menu.day).format('dddd').toUpperCase()}
                    </td>
                    <td className="first">SNÍDANĚ</td>
                    <td className="first">
                      {!menu.info
                        ? menu.foodIds.filter((f) => f.type === 'breakfast').length
                          ? menu.foodIds.filter((f) => f.type === 'breakfast').map((f) => <Food key={`menu_breakfast_${menu.id}_food_${f.id}`} data={f} />)
                          : 'Tento den se nevaří snídaně'
                        : menu.info}
                    </td>
                  </tr>
                  <tr onClick={() => history.push(`/admin/menus/preview/${menu.id}`)}>
                    <td>OBĚDY</td>
                    <td>
                      {!menu.info
                        ? menu.foodIds.filter((f) => f.type === 'lunch').length
                          ? menu.foodIds.filter((f) => f.type === 'lunch').map((f) => <Food key={`menu_lunch_${menu.id}_food_${f.id}`} data={f} />)
                          : 'Tento den se nevaří obědy'
                        : menu.info}
                    </td>
                  </tr>
                  <tr onClick={() => history.push(`/admin/menus/preview/${menu.id}`)}>
                    <td>VEČEŘE</td>
                    <td>
                      {!menu.info
                        ? menu.foodIds.filter((f) => f.type === 'dinner').length
                          ? menu.foodIds.filter((f) => f.type === 'dinner').map((f) => <Food key={`menu_dinner_${menu.id}_food_${f.id}`} data={f} />)
                          : 'Tento den se nevaří večeře'
                        : menu.info}
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        )}
      </Section>
    </div>
  );
};

export default MenusList;
