import React from 'react';
import moment from 'moment';

import AdminLoginForm from '../../../forms/UserForms/AdminLoginForm';

const AdminLogin = () => {
  return (
    <div id="login-background">
      <div id="login">
        <h1>ADMINISTRACE</h1>
        <h2>DNES {moment().format('dddd D.M').toUpperCase()}</h2>
        <AdminLoginForm />
      </div>
    </div>
  );
};

export default AdminLogin;
