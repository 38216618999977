import axios from 'axios';
import { notify } from 'reapop';

import { ENDPOINTS } from '../../constants';
import { FOODS_ALL, FOODS_CREATE, FOODS_FAILURE, FOODS_LOADING, FOODS_ONE, FOODS_REMOVE, FOODS_UPDATE } from '../types/foodsTypes';
import { processFoods } from '../helpers/foodsHelpers';

const AFoodsLoading = (name) => ({
  type: FOODS_LOADING,
  name,
});

const AFoodsFailure = (name, error) => ({
  type: FOODS_FAILURE,
  name,
  error,
});

const AGetFoods = (foods) => ({
  type: FOODS_ALL,
  foods,
});

const AGetFood = (food) => ({
  type: FOODS_ONE,
  food,
});

const ACreateFood = (food) => ({
  type: FOODS_CREATE,
  food,
});

const AUpdateFood = (food) => ({
  type: FOODS_UPDATE,
  food,
});

const ARemoveFood = (food) => ({
  type: FOODS_REMOVE,
  food,
});

export const getFoods = () => async (dispatch) => {
  const actionName = 'all';
  try {
    dispatch(AFoodsLoading(actionName));
    const response = await axios.get(ENDPOINTS.foods);
    const { data } = response;
    const foods = processFoods(data);
    dispatch(AGetFoods(foods));
  } catch (e) {
    dispatch(AFoodsFailure(actionName, e));
    dispatch(
      notify({
        title: 'Nepodařilo se získat seznam jídel',
        message: e.response.data.message,
        status: 'error',
        dismissAfter: 3000,
      }),
    );
  }
};

export const getFood = (foodId) => async (dispatch) => {
  const actionName = 'one';
  dispatch(AFoodsLoading(actionName));
  try {
    const response = await axios.get(`${ENDPOINTS.foods}/${foodId}`);
    const { data } = response;
    dispatch(AGetFood(data));
  } catch (e) {
    dispatch(AFoodsFailure(actionName, e));
    dispatch(
      notify({
        title: 'Nepodařilo se získat informace o jídle',
        message: e.response.data.message,
        status: 'error',
        dismissAfter: 3000,
      }),
    );
  }
};

export const createFood = (token, values) => async (dispatch) => {
  const actionName = 'create';
  dispatch(AFoodsLoading(actionName));
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.post(ENDPOINTS.foods, values, config);
    const { data } = response;
    dispatch(ACreateFood(data));
    dispatch(
      notify({
        title: `Jidlo ${data.name} bylo přidáno`,
        status: 'success',
        dismissAfter: 3000,
      }),
    );
  } catch (e) {
    dispatch(AFoodsFailure(actionName, e));
    dispatch(
      notify({
        title: `Jídlo se nepodařilo přidat`,
        message: e.response.data.message,
        status: 'error',
        dismissAfter: 3000,
      }),
    );
  }
};

export const updateFood = (token, foodId, values) => async (dispatch) => {
  const actionName = 'update';
  dispatch(AFoodsLoading(actionName));
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.put(`${ENDPOINTS.foods}/${foodId}`, values, config);
    const { data } = response;
    dispatch(AUpdateFood(data));
    dispatch(
      notify({
        title: `Jídlo ${data.name} bylo upraveno`,
        status: 'success',
        dismissAfter: 3000,
      }),
    );
  } catch (e) {
    dispatch(AFoodsFailure(actionName, e));
    dispatch(
      notify({
        title: 'Jídlo se nepodařilo upravit',
        message: e.response.data.message,
        status: 'error',
        dismissAfter: 3000,
      }),
    );
  }
};

export const removeFood = (token, food) => async (dispatch) => {
  const actionName = 'remove';
  dispatch(AFoodsLoading(actionName));
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    await axios.delete(`${ENDPOINTS.foods}/${food.id}`, config);
    dispatch(ARemoveFood(food));
    dispatch(
      notify({
        title: `Jídlo ${food.name} bylo odstraněno`,
        status: 'success',
        dismissAfter: 3000,
      }),
    );
  } catch (e) {
    dispatch(AFoodsFailure(actionName, e));
    dispatch(
      notify({
        title: 'Jídlo se nepodařilo odstranit',
        message: e.response.data.message,
        status: 'error',
        dismissAfter: 3000,
      }),
    );
  }
};
