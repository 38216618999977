export const SS_KEYS = {
  TOKEN: '@winebar/token',
  USER: '@winebar/user',
};

class SessionStore {
  static loadItem(key) {
    return sessionStorage.getItem(key);
  }

  static storeItem(key, item) {
    sessionStorage.setItem(key, item);
  }

  static removeItem(key) {
    sessionStorage.removeItem(key);
  }

  static clear() {
    sessionStorage.clear();
  }
}

export default SessionStore;
