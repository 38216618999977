import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { faHandPointLeft } from '@fortawesome/free-solid-svg-icons/faHandPointLeft';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { Button, Modal, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { useHistory } from 'react-router-dom';

import { getAllergens, removeAllergen } from '../../../redux/actions/allergensActions';
import Section from '../../../components/Section/Section';
import Loading from '../../../components/Loading/index';
import MyButton from '../../../components/MyButton/index';
import GridSection from '../../../components/Section/GridSection';

const AllergensList = () => {
  const history = useHistory();

  const token = useSelector((state) => state.user.token);
  const loading = useSelector((state) => state.admin.allergens.loadings.allergensAll);
  const loadingRemove = useSelector((state) => state.admin.foods.loadings.allergensRemove);
  const allergens = useSelector((state) => Object.values(state.admin.allergens.items));

  const dispatch = useDispatch();

  const [selectedAllergen, setSelectedAllergen] = useState();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!loading) {
      dispatch(getAllergens());
    }
  }, []);

  const handleRemove = (allergen) => {
    if (!loadingRemove) {
      dispatch(removeAllergen(token, allergen));
      setShowModal(false);
      setSelectedAllergen(undefined);
    }
  };

  const allergensSorted = allergens.sort((a, b) => a.name.localeCompare(b.name));

  const tooltipView = <Tooltip id="tooltip-view">Zobrazit alergen</Tooltip>;
  const tooltipEdit = <Tooltip id="tooltip-edit">Upravit alergen</Tooltip>;
  const tooltipRemove = <Tooltip id="tooltip-remove">Odstranit alergen</Tooltip>;

  return (
    <div>
      <GridSection heading="ADMINISTRACE">
        <MyButton link="/admin" variant="outline-dark" icon={faHandPointLeft} text="ZPĚT" />
        <MyButton link="/admin/allergens/add" variant="success" icon={faPlusCircle} text="PŘIDAT ALERGEN" />
      </GridSection>
      <Section heading="SEZNAM ALERGENŮ">
        {loading ? (
          <Loading />
        ) : (
          <Table responsive striped>
            <thead>
              <tr>
                <th>Název</th>
                <th style={{ textAlign: 'right' }}>Možnosti</th>
              </tr>
            </thead>
            <tbody>
              {allergensSorted.map((allergen) => (
                <tr key={`allergen_${allergen.id}`}>
                  <td>{allergen.name}</td>
                  <td style={{ textAlign: 'right' }}>
                    <OverlayTrigger placement="top" overlay={tooltipView}>
                      <Button variant="light" onClick={() => history.push(`/admin/allergens/preview/${allergen.id}`)}>
                        <FontAwesomeIcon icon={faEye} />
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger placement="top" overlay={tooltipEdit}>
                      <Button variant="light" onClick={() => history.push(`/admin/allergens/edit/${allergen.id}`)}>
                        <FontAwesomeIcon icon={faEdit} />
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger placement="top" overlay={tooltipRemove}>
                      <Button
                        variant="danger"
                        onClick={() => {
                          setSelectedAllergen(allergen);
                          setShowModal(true);
                        }}>
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </OverlayTrigger>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Section>
      {selectedAllergen && (
        <Modal show={showModal} size="lg" onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Odstranění alergenu</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{`Opravdu chcete odstranit alergen ${selectedAllergen.name}?`}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={() => handleRemove(selectedAllergen)}>
              Odstranit
            </Button>
            <Button variant="outline-dark" onClick={() => setShowModal(false)}>
              Zavřít
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default AllergensList;
