import React from 'react';
import NotificationsSystem, { wyboTheme, dismissNotification } from 'reapop';
import { useDispatch, useSelector } from 'react-redux';

const Notifications = () => {
  const notifications = useSelector((state) => state.notifications);

  const dispatch = useDispatch();

  return <NotificationsSystem notifications={notifications} dismissNotification={(id) => dispatch(dismissNotification(id))} theme={wyboTheme} />;
};

export default Notifications;
